import Section from "./Section";

const NotifierSection = ({ formData, handleChange }) => {
  return (
    <Section>
      <Section.Title>
        <h4>Notifier endpoint</h4>
      </Section.Title>
      <div className="request-modification-tab-content__sections__section__inputs request-modification-tab-content__sections__section__inputs--notify-info">
        <div className="request-modification-tab-content__sections__section__radio input-content">
          <div className="input-content__title">
            <span>Notifications type</span>
          </div>
          <div className="input-content__radios input-content__radios--secondary">
            <input
              type="radio"
              value="statusReport"
              name="notifications"
              id="statusReport"
              checked={formData.statusReport}
              onChange={handleChange}
            />
            <label htmlFor="statusReport">
              <div className="input-content__radios__radio">
                <div className="input-content__radios__radio__content">
                  <span className="input-content__radios__radio__title">
                    Status report hub messages
                  </span>
                  <span className="input-content__radios__radio__description">
                    Brief description
                  </span>
                </div>
              </div>
            </label>
            <input
              type="radio"
              value="webhoot"
              name="notifications"
              id="webhoot"
              checked={formData.webhoot}
              onChange={handleChange}
            />
            <label htmlFor="webhoot">
              <div className="input-content__radios__radio">
                <div className="input-content__radios__radio__content">
                  <span className="input-content__radios__radio__title">
                    Webhook
                  </span>
                  <span className="input-content__radios__radio__description">
                    Brief description
                  </span>
                </div>
              </div>
            </label>
          </div>
        </div>
        <div className="request-modification-tab-content__sections__section__radio input-content">
          <div className="input-content__title">
            <span>Notifier security</span>
          </div>
          <div className="input-content__radios input-content__radios--secondary">
            <input
              type="radio"
              value="oauthSecurity"
              name="notifications_sec"
              id="oauthSecurity"
              onChange={handleChange}
              checked={formData.oauthSecurity}
            />
            <label htmlFor="oauthSecurity">
              <div className="input-content__radios__radio">
                <div className="input-content__radios__radio__content">
                  <span className="input-content__radios__radio__title">
                    OAuth 2.0
                  </span>
                  <span className="input-content__radios__radio__description">
                    Brief description
                  </span>
                </div>
              </div>
            </label>
            <input
              type="radio"
              value="basicSecurity"
              name="notifications_sec"
              id="basicSecurity"
              onChange={handleChange}
              checked={formData.basicSecurity}
            />
            <label htmlFor="basicSecurity">
              <div className="input-content__radios__radio">
                <div className="input-content__radios__radio__content">
                  <span className="input-content__radios__radio__title">
                    HTTP Basic
                  </span>
                  <span className="input-content__radios__radio__description">
                    Brief description
                  </span>
                </div>
              </div>
            </label>
            <input
              type="radio"
              value="notSecurity"
              name="notifications_sec"
              id="notSecurity"
              onChange={handleChange}
              checked={formData.notSecurity}
            />
            <label htmlFor="notSecurity">
              <div className="input-content__radios__radio">
                <div className="input-content__radios__radio__content">
                  <span className="input-content__radios__radio__title">
                    Without security
                  </span>
                  <span className="input-content__radios__radio__description">
                    Brief description
                  </span>
                </div>
              </div>
            </label>
          </div>
        </div>

        {/* CAMPOS DE TEXTO PARA SEGURIDAD OAUTH */}
        {formData.oauthSecurity && (
          <>
            <div className="input-content">
              <div className="input-content__title">
                <span>Functional endpoint</span>
              </div>
              <label>
                <input
                  type="text"
                  name="oauthParam.notificationUrl"
                  value={formData.oauthParam.notificationUrl}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="input-content">
              <div className="input-content__title">
                <span>Webhook Client ID</span>
              </div>
              <label>
                <input
                  type="text"
                  name="oauthParam.clientID"
                  value={formData.oauthParam.clientID}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="input-content">
              <div className="input-content__title">
                <span>Client Secret</span>
              </div>
              <label>
                <input
                  type="text"
                  name="oauthParam.clientSecret"
                  value={formData.oauthParam.clientSecret}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="input-content">
              <div className="input-content__title">
                <span>Token URI</span>
              </div>
              <label>
                <input
                  type="text"
                  name="oauthParam.tokenUrl"
                  value={formData.oauthParam.tokenUrl}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </label>
            </div>
          </>
        )}

        {/* CAMPOS DE TEXTO PARA SEGURIDAD BÁSICA */}
        {formData.basicSecurity && (
          <>
            <div className="input-content">
              <div className="input-content__title">
                <span>Functional endpoint</span>
              </div>
              <label>
                <input
                  type="text"
                  name="basicSecurityParam.notificationUrl"
                  value={formData.basicSecurityParam.notificationUrl}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="input-content">
              <div className="input-content__title">
                <span>User</span>
              </div>
              <label>
                <input
                  type="text"
                  name="basicSecurityParam.user"
                  value={formData.basicSecurityParam.user}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="input-content">
              <div className="input-content__title">
                <span>Password</span>
              </div>
              <label>
                <input
                  type="text"
                  name="basicSecurityParam.pass"
                  value={formData.basicSecurityParam.pass}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </label>
            </div>
          </>
        )}

        {/* CAMPOS DE TEXTO PARA NO SEGURIDAD */}
        {formData.notSecurity && (
          <>
            <div className="input-content">
              <div className="input-content__title">
                <span>Token URI</span>
              </div>
              <label>
                <input
                  type="text"
                  name="notSecurityParam.tokenUrl"
                  value={formData.notSecurityParam.tokenUrl}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </label>
            </div>
          </>
        )}
      </div>
    </Section>
  );
};

export default NotifierSection;
