import { React, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { error as showError } from "../../../utils/NotificationsContainer";

import { useGlobalVar } from "../../../utils/GlobalContext";

import BackButtonComponent from "../../../atoms/back-button-component/BackButtonComponent";
import InputComponent from "../../../atoms/input-component/InputComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";

import {
	getOrganizationsList,
	getOrganizationsTypes,
	createOrganization,
} from "../../../../services/ApiOrganization";

import { MANAGE_ORGANIZATIONS } from "../../../utils/Routes";

const CreateOrganization = () => {
	const navigate = useNavigate();
	const { accessToken, setLoggedOut } = useGlobalVar();
	const [organizationType, setOrganizationType] = useState([]);
	const [organizationList, setOrganizationList] = useState([]);
	const [loading, setLoading] = useState(true);

	const [formData, setFormData] = useState({
		name: "",
		codigoOrganization: "",
		email: "",
		contract: "true",
		cif: "",
		clientOfClient: false,
		directClient: "",
		users: [
			{
				firstname: "",
				lastname: "",
				username: "",
				email: "",
				phone: "",
				rol: "ADMIN",
			},
		],
	});

	const logout = useCallback(() => {
		setLoggedOut();
		navigate("/logout");
	}, [navigate, setLoggedOut]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const organizationListData = await getOrganizationsList(
					accessToken
				);
				const typeOrganizationData = await getOrganizationsTypes(
					accessToken
				);
				setOrganizationList(organizationListData);
				setOrganizationType(typeOrganizationData);
			} catch (error) {
				if (error.response.status === 403) {
					logout();
				}
				console.error("Error fetching data:", error);
			}
			setLoading(false);
		};

		fetchData();
	}, [accessToken, logout]);

	const directOrganizations = organizationList
		.filter((org) => org.clientOfClient)
		.map((org) => ({
			label: org.nameOrganization,
			value: org.nameOrganization,
		}));

	const handleChange = (name, value) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleUserChange = (index, name, value) => {
		if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				// Asegura que solo se acepten números y hasta 9 dígitos
				setFormData((prevFormData) => {
					const newUsers = prevFormData.users.map((user, i) =>
						i === index ? { ...user, [name]: value } : user
					);
					return {
						...prevFormData,
						users: newUsers,
					};
				});
			}
		} else {
			setFormData((prevFormData) => {
				const newUsers = prevFormData.users.map((user, i) =>
					i === index ? { ...user, [name]: value } : user
				);
				return {
					...prevFormData,
					users: newUsers,
				};
			});
		}
	};

	const handleSubmit = async (e) => {
		if (e) e.preventDefault();

		try {
			const result = await createOrganization(formData, accessToken);

			localStorage.setItem("reloadOrgCreated", "true");

			navigate(MANAGE_ORGANIZATIONS);
			return result;
		} catch (error) {
			if (error.response.status === 403) {
				logout();
			}
			showError("generalError");
			console.error("Error:", error);
		}
	};

	const organizationOptions = organizationType.map((org) => ({
		label: org.description,
		value: org.codOrganization,
	}));

	const handleCancel = () => {
		if (window.history.length > 2) {
			navigate(-1);
		} else {
			navigate("/ruta-predeterminada");
		}
	};

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div className="request-subscription create-organization">
			<div className="request-subscription__heading">
				<div className="request-subscription__heading__back-button">
					<BackButtonComponent text={"Back to Organizations list"} />
				</div>
				<h1>New Organization</h1>
				<div className="request-subscription__form">
					<div className="request-subscription__form__input">
						<div className="request-subscription__form__input__title p p--b-2">
							<h4>User information</h4>
						</div>
					</div>
					<div className="create-organization__form__content">
						<div className="input-content p p--b-2">
							<div className="create-organization__form">
								<InputComponent
									type="text"
									name="name"
									label="Name"
									placeholder="Name"
									value={formData.name}
									onChange={handleChange}
								/>
								<div className="input-content p p--b-2">
									<InputComponent
										placeholder={"Select type"}
										type="select"
										name="codigoOrganization"
										label={"Type of organization"}
										options={organizationOptions}
										value={formData.codigoOrganization}
										onChange={handleChange}
									/>
								</div>
								<InputComponent
									type="email"
									name="email"
									label="Email"
									placeholder="Insert Organization email"
									value={formData.email}
									onChange={handleChange}
								/>
								<InputComponent
									type="text"
									name="cif"
									label="CIF"
									placeholder="Insert Organization CIF"
									value={formData.cif}
									onChange={handleChange}
								/>
								<InputComponent
									type="toggle"
									name="clientOfClient"
									label="Indirect Client"
									value={formData.clientOfClient}
									onChange={handleChange}
								/>
								{formData.clientOfClient ? (
									<InputComponent
										placeholder="Select client"
										type="select"
										name="directClient"
										label={
											"Organization of wich it is a client"
										}
										options={directOrganizations}
										value={formData.directClient}
										onChange={handleChange}
									/>
								) : (
									<></>
								)}
							</div>
							<DividerComponent />
							<div className="create-organization__form">
								<div className="request-subscription__form__input__title p p--b-2">
									<h4>Main administrator information</h4>
								</div>
								{formData.users.map((user, index) => (
									<div key={index}>
										<div>
											<InputComponent
												type="text"
												name="firstname"
												label="Name"
												placeholder="Insert Administrator Name"
												value={user.firstname}
												onChange={(name, value) =>
													handleUserChange(
														index,
														name,
														value
													)
												}
											/>
											<InputComponent
												type="text"
												name="lastname"
												label="Lastname"
												placeholder="Insert Administrator Last name"
												value={user.lastname}
												onChange={(name, value) =>
													handleUserChange(
														index,
														name,
														value
													)
												}
											/>
											<InputComponent
												type="email"
												name="email"
												label="Email"
												placeholder="Insert Administrator email"
												value={user.email}
												onChange={(name, value) =>
													handleUserChange(
														index,
														name,
														value
													)
												}
											/>
											<InputComponent
												type="tel"
												name="phone"
												label="Phone and country code (Optional)"
												placeholder="Insert Administrator phone number with the country code"
												value={user.phone}
												onChange={(name, value) =>
													handleUserChange(
														index,
														name,
														value
													)
												}
											/>
										</div>
									</div>
								))}
							</div>
							<DividerComponent />
							<div className="create-organization__buttons">
								<ButtonComponent
									text={"Cancel"}
									onClick={handleCancel}
									color={"button-component--neutral"}
								/>
								<ButtonComponent
									text={"Add new Organization"}
									onClick={handleSubmit}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateOrganization;
