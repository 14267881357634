import React, { useState } from "react";
import ModalComponent from "../../molecules/modal-component/ModalComponent";
import { useLocation, useNavigate } from "react-router-dom";

import { error as showError } from "../../utils/NotificationsContainer";

import { createPassword } from "../../../services/ApiAuth";

import ButtonComponent from "../../atoms/button-component/ButtonComponent";
import InputComponent from "../../atoms/input-component/InputComponent";

import { INDEX } from "../../utils/Routes";

const AddPasswordPage = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [showConditionsModal, setShowConditionsModal] = useState(false);
	const [showPrivacyModal, setShowPrivacyModal] = useState(false);

	const openConditionsModal = () => setShowConditionsModal(true);
	const closeConditionsModal = () => setShowConditionsModal(false);

	const openPrivacyModal = () => setShowPrivacyModal(true);
	const closePrivacyModal = () => setShowPrivacyModal(false);

	const [formData, setFormData] = useState({
		policy: "",
		comertial: "",
	});

	const queryParams = new URLSearchParams(location.search);

	const user = queryParams.get("user");

	const verificationCode = queryParams.get("code");

	const invited = queryParams.get("invited");

	const [userPassword, setUserPassword] = useState({
		email: user,
		confirmationCode: verificationCode,
		password: "",
		confirmPassword: "",
	});


	const handleChange = (name, value) => {
		setUserPassword((prevUserPassword) => ({
			...prevUserPassword,
			[name]: value,
		}));
	};

	const handleInputChange = (e) => {
		const { name, type, checked } = e.target;

		if (type === "checkbox") {
			setFormData({
				...formData,
				[name]: checked,
			});
		}
	};

	// Validaciones de la contraseña
	const isLengthValid = userPassword.password.length >= 8;
	const hasUpperCase = /[A-Z]/.test(userPassword.password);
	const hasLowerCase = /[a-z]/.test(userPassword.password);
	const hasNumber = /[0-9]/.test(userPassword.password);
	const hasSpecialChar = /[!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~¿¡€¬]/.test(userPassword.password);
	const passwordsMatch =
		userPassword.password === userPassword.confirmPassword;

	const isFormValid = () => {
		return (
			isLengthValid &&
			hasUpperCase &&
			hasLowerCase &&
			hasNumber &&
			hasSpecialChar &&
			passwordsMatch
		);
	};

	// Determinar el color del texto según el estado
	const getValidationColor = (isValid) => {
		return userPassword.password.length === 0
			? "#52627A"
			: isValid
				? "#37A685"
				: "#C41C1C";
	};

	// Determinar el símbolo a mostrar según el estado
	const getValidationSymbol = (isValid) => {
		return userPassword.password.length === 0 ? "" : isValid ? "✔️" : "X";
	};

	const handleSubmit = async (e) => {
		if (e) e.preventDefault();

		if (invited === 'true' && !formData.policy) {
			showError("acceptTermsAndConditions");
			return;
		}
		try {
			const { confirmPassword, ...dataToSend } = userPassword; // Omitir confirmPassword en la solicitud

			const cleanedPayload = JSON.parse(JSON.stringify(dataToSend));
			await createPassword(cleanedPayload);

			navigate(INDEX);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	return (
		<>
			<div className="login-form__addpassword">
				<div className="login-full-screen-layout__form__logo"></div>
				<div className="password-form">
					<form>
						<h1 className="login-form__addpassword__title">Add your password </h1>
						<div>

							<InputComponent
								type="password"
								name="password"
								value={userPassword.password}
								label="Password"
								placeholder="Insert your new password"
								onChange={handleChange}
							/>

							<InputComponent
								n
								type="password"
								name="confirmPassword"
								value={userPassword.confirmPassword}
								label="Confirm Password"
								placeholder="Repeat your new password"
								onChange={handleChange}
							/>



							<div className="p p--b-3">
								<ul style={{ listStyleType: "none", padding: 0 }}>
									<li
										style={{
											color: getValidationColor(isLengthValid),
										}}
									>
										{getValidationSymbol(isLengthValid)} At least 8
										characters
									</li>
									<li
										style={{
											color: getValidationColor(hasUpperCase),
										}}
									>
										{getValidationSymbol(hasUpperCase)} At least one
										uppercase letter
									</li>
									<li
										style={{
											color: getValidationColor(hasLowerCase),
										}}
									>
										{getValidationSymbol(hasLowerCase)} At least one
										lowercase letter
									</li>
									<li
										style={{
											color: getValidationColor(hasNumber),
										}}
									>
										{getValidationSymbol(hasNumber)} At least one
										number
									</li>
									<li
										style={{
											color: getValidationColor(hasSpecialChar),
										}}
									>
										{getValidationSymbol(hasSpecialChar)} At least
										one special character (except -, [, ], y ^)
									</li>
								</ul>
							</div>
							{!passwordsMatch &&
								userPassword.confirmPassword.length > 0 && (
									<div
										className="p p--b-4"
										style={{ color: "red", marginTop: "10px" }}
									>
										❌ Passwords do not match
									</div>
								)}

							{
								invited === 'true' ?
									<div className="password-form__legal-input">
										<div className="input-content">
											<div className="input-content__checkbox">
												<label>
													<input
														type="checkbox"
														name="policy"
														onChange={handleInputChange}
														checked={formData.policy}
													/>
													<div className="checkmark">
														<div className="check"></div>
													</div>
													<p>
														By creating an account I accept the{" "}
														<span onClick={openConditionsModal}>
															terms and conditions
														</span>{" "}
														and I've read and understand the{" "}
														<span onClick={openPrivacyModal}>
															privacy notice
														</span>
														.
													</p>
												</label>
											</div>
										</div>
										<div className="input-content">
											<div className="input-content__checkbox">
												<label>
													<input
														type="checkbox"
														name="comertial"
														onChange={handleInputChange}
														checked={formData.comertial}
													/>
													<div className="checkmark">
														<div className="check"></div>
													</div>
													<p>
														I consent to receive commercial
														information
													</p>
												</label>
											</div>
										</div>
									</div> : null
							}


						</div>

						<button
							className="login-form__button"
							onClick={handleSubmit}
							disabled={!isFormValid()}
						>
							Save your password
						</button>

					</form>
				</div>



				<ModalComponent
					show={showConditionsModal}
					onClose={closeConditionsModal}
					size={"xxl"}
				>
					<div className="terms-conditions">
						<div className="terms-conditions__header">
							<h3>Terms and conditions</h3>
						</div>

						<div className="terms-conditions__text">
							<p>
								<span>IMPORTANT INFORMATION</span> <br /> <br />
								<p>
									Please carefully read these terms and conditions (hereinafter, the "Terms") before using our private area of the portal for developers (hereinafter, the "Private Portal") or any of the services therein.<br/>
									By accessing, visiting or using the Private Portal, clicking on the “accept” button, or otherwise indicating assent, You (as defined below) are agreeing to be bound by these Terms and affirming that you are of legal age to enter into these Terms. If you do not agree to the Terms, do not access or use the Private Portal.<br/>
									For clarity purposes, the term “use” includes, but is not limited to, accessing, browsing, inputting information, and registering into the Private Portal to use the Services. Any reference to “you”, “You” or “Your”, means You as a user of the Private Portal and/or the API Services available through the Private Portal (“User”), and any reference to “we”, “We”, “us”, “Us” or “our” is to PagoNxt Payments Services, S.L. You and We mutually acknowledge sufficient legal capacity and competence for executing these Terms. <br/>
									Access to the Private Portal to use the API Services in Sandbox Environment requires registration as a User, and access to the Private Portal to use the API Services in Pre and Live Environment requires registration and subscription of an agreement with Us, as explained below. <br /> <br /> <br />
								</p>
								<span>1. INTRODUCTION</span>
								<br /> <br />
								<p>
									PagoNxt Payments Services, S.L. (“PagoNxt”) is a Spanish company domiciled in Avda. de Cantabria s/n, Ciudad Grupo Santander, 28660, Boadilla del Monte (Madrid) and with tax identification number B87959326. PagoNxt Payments Services, S.L. is registered in the Corporate Registry of Madrid, volume 41220, sheet 29, page M-655666, entry 13. <br />
									PagoNxt is the owner or licensee of all intellectual property rights and of any other type of rights on the Private Portal.
									<br />PagoNxt provides payments processing technology services and swift bureau services to financial services companies (“Direct Customers”). Direct Customers may, in certain circumstances, allow to use such technology directly by their final customers (“Indirect User”).
									<br />The Privacy Policy in which we explain the terms under which We process personal data of Users is available here.<br/>
								</p>
								<span>2. PURPOSE AND SCOPE</span> <br />
								<br />
								<p>The Private Portal is a technological tool that allows access to certain services (the “Services”). These Services may include:</p>
								<ul>
									<li>A. API Services: the services and/or functionalities accessible or executed through an specific API (ie. an Application Programming Interface –or any other similar technology or software component— offered by Us through the Private Portal to allow You to access and use any of Our API Services as available at any given time, through Your application(s)). Depending on the level of engagement You acquire with Us, API Services can be in Sandbox Environment or in Pre and Live Environment, as described below.</li>
									<li>B. SDKs: Software Development Kits offered by Us to facilitate use of the Private Portal and time to market.</li>
								</ul>
								<br />
								<br />
								<span>3. ABOUT THE PRIVATE PORTAL</span> <br />
								<br />
								<span>3.1. USE OF THE PRIVATE PORTAL</span>
								<br />
								<br />
								<p>The Private Portal offers two modalities of use:</p>
								<br />
								<ul>
									<li>A. Use of the Private Portal to access to the API Services in Sandbox Environment, which requires to create an account but not to contract any Services. This use will be governed by these Terms.</li>
									<li>B. Use of the Private Portal to access to the API Services in Pre and Live Environment, which requires to create an account and to subscribe an agreement with Us under the following conditions:
										<ul>
											<li>If You are or act on behalf of a Direct Customer, Your use of the API Services in Pre and Live Environment will be governed by these Terms and by the services agreement signed between such Direct Customer and Us (“Direct Agreement”). In case of discrepancy, the Direct Agreement shall prevail.</li>
											<li>If You are an Indirect User, the relevant Direct Customer will be Your only counterparty for the API Services in Pre and Live Environment. For this, Your agreement with the relevant Direct Customer (“Indirect Agreement”) shall govern Your use of the API Services in Pre and Live Environment. In these cases, PagoNxt will only be acting as a technology provider of the Direct Customer and will not be liable for the API Services in Pre and Live Environment towards the Indirect User in any case.</li>
										</ul>
									</li>
								</ul>
								<p>For accessing to the API Services in Pre and Live Environment, please contact Us through <a href="mailto:payments.onboarding@pagonxt.com ">payments.onboarding@pagonxt.com</a> so We can explain how to proceed.</p>
								<br />
								<span>3.2. ACCESS TO THE PRIVATE PORTAL</span>{" "}
								<p>
									<br />Regardless of the chosen mode of use, Your navigation through the Private Portal requires to create an account with login credentials. Once the account is created, we will share with You the token or access code necessary to use the API Services that You have previously agreed with Us.
									<br />
									By creating an account, You fully accept and commit to comply with these Terms and with any instructions or recommendations made available from time to time on the Private Portal.
									<br />You must treat Your login credentials as confidential and not disclose them to any third party. We have the right to disable any User identification code or password, whether chosen by You or allocated by Us, at any time, if in Our reasonable opinion You have failed to comply with any of the provisions of these Terms. If You know or suspect that anyone other than You knows Your user identification code or password, You must promptly notify Us at payments.support@pagonxt.com.
									<br />Passwords are confidential and non-transferable and shall be valid indefinitely until You modify Your password using the tools available to You on the Private Portal for this purpose. It shall be generated by You following Our password security guidelines as available from time to time. In any case, We have the necessary functionalities allowing You to modify Your password when You deem necessary.<br />
									You shall make diligent use of Your password and shall not make it available to any third party. You shall be responsible for keeping adequate custody of Your login credentials. You shall be liable for any illicit use of the Private Portal by any illegitimate third party who accesses the Private Portal using Your password.
								</p>
								<span>3.3. API SERVICES</span> <br />
								<br />
								<span>3.3.1. SANDBOX ENVIRONMENT</span> <br />
								<br />
								Some API Services might be available in sandbox
								environment (“Sandbox Environment”). The Sandbox
								Environment constitutes a trial or evaluation period
								and is based on a controlled (and therefore
								fictitious) environment that has no impact on the
								real world. Thus, any use that You make of the API
								Services offered through the Private Portal in
								Sandbox Environment is limited to a testing
								environment with the specific purpose of trying out
								the functionalities of the API Services and
								verifying the interoperability of Your systems prior
								to submitting Your application to enter live
								environment (as described below). You acknowledge
								that Your use of the API Services in Sandbox
								Environment will not constitute access or connection
								to databases of the entity providing the Services.
								Instead, the data that shall appear during Your use
								of the API Services will be completely fictitious
								and for testing purposes only. Likewise, You
								acknowledge and accept that, to the extent that the
								Sandbox Environment is based on a controlled trial
								environment, We may, but do not assume the
								obligation to, provide support or assistance. This
								is a fictitious environment made available to You
								for testing purposes only. <br />
								<br />
								<br />
								<span>3.3.2. PRE ENVIRONMENT</span> <br />
								<br />
								Pre Environment is a trial environment with no
								impact on the real world but with access
								(connection) to the databases and information of the
								entity which provides the particular Service (“Pre
								Environment”). It is the previous validation step
								before going into production in Live Environment.
								Access to Pre Environment requires to subscribe a
								Direct Agreement with Us or an Indirect Agreement
								with one of our Direct Customers. The contracting
								process will take place offline. You expressly
								acknowledge and accept that We reserve the right to
								discretionally approve or reject Your request,
								without having to justify the decision. <br />
								<br />
								<br />
								<span>3.3.3. LIVE ENVIRONMENT</span> <br />
								<br />
								Live environment is a production environment with
								impact on the real world and with access
								(connection) to the databases and information of the
								entity which provides the particular service (“Live
								Environment”). Access to Live Environment requires
								to subscribe a Direct Agreement with Us or an
								Indirect Agreement with one of our Direct Customers.
								The contracting process will take place offline. You
								expressly acknowledge and accept that We reserve the
								right to discretionally approve or reject your
								request, without having to justify the decision.{" "}
								<br />
								<br />
								<br />
								<span>
									3.4. LIMITATIONS OF USE OF THE PRIVATE PORTAL
								</span>{" "}
								<p>It is expressly forbidden to use or access the Private Portal for illegal or non-authorised purposes. Obligations under this clause apply to all Users. In particular, the following actions are not allowed:
									<ul>
										<li>make available through or in connection with the Private Portal any materials that are defamatory, offensive or otherwise objectionable;</li>
										<li>post any virus that is potentially harmful or invasive or intended 
											to damage the operation of, or to monitor the use of the Private Portal;</li>
										<li>scrape, gather Private Portal content, or circumvent the navigational structure or presentation of the Private Portal, without our prior written consent;</li>
										<li>exploit the Private Portal without our prior written consent;</li>
										<li>make alterations to the Private Portal, or permit the Private Portal or any part of it to be combined with, or become incorporated in, any other programs;</li>
										<li>disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the Private Portal or attempt to do any such thing except solely to the extent that applicable law specifically prohibits such restrictions (such as under sec. 100.5 of the Spanish Copyright Act, provided that You shall first ask us to provide the necessary information, shall set a reasonable deadline for us to do so, and such deadline has passed without us having provided such information);</li>
										<li>provide or otherwise make available the Private Portal (including object and source code) to a third party, without our prior written consent;</li>
										<li>create Internet links to the Private Portal or frame or mirror the Private Portal, or host the Private Portal on a server (including a website) or in any other manner;</li>
										<li>to the maximum extent permitted by applicable law, access or use the Private Portal in order to build a competitive product or service;</li>
										<li>use the Private Portal in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these Terms;</li>
										<li>infringe our intellectual property rights or those of any third party in relation to Your use of the Private Portal (to the extent that such use is not licensed by these Terms); and</li>
										<li>use the Private Portal in a way that could damage or compromise our systems or security or interfere with other users of the Private Portal.</li>
									</ul>
									Failure to comply with any of the aforementioned obligations may entail the application of any measures that We deem necessary or merely convenient, without the obligation to compensate You for any damages which may arise as a result and regardless of the possible action for damages against You.<br />
									We expressly reserve the right to suspend, block, modify, restrict, or temporarily or permanently interrupt Your access, navigation and/or use of the Private Portal, with or without prior notice, if We notice that You are infringing applicable legislation, these Terms or for unforeseen risks derived from the use of the Private Portal.
								</p>
								
								<span>
									4. THIRD PARTY WEBSITES AND SERVICES
								</span>{" "}
								<br />
								<br />
								<span>4.1. SERVICES</span> <br />
								<br />
								If You are an Indirect User, Your use of the API
								Services in Pre or Live Environment is at Your own
								risk. Your use of the API Services in Pre or Live
								Environment may be subject to additional or
								different terms, conditions and policies with third
								parties as described in section 3.1. above. <br />
								<br />
								<br />
								<span>4.2. LINK TO THIRD-PARTY WEBSITES</span>{" "}
								<br />
								<br />
								In the event that the Private Portal displays
								hyperlinks to other websites by means of buttons,
								links, banners or embedded content, We inform You
								that, unless otherwise specified, these are managed
								and under the control of third parties. We have no
								control or means, whether technical or human, to
								monitor, control or approve the information,
								contents or services provided by other platforms or
								websites that may be linked to from our Private
								Portal. Consequently, We shall not be held liable
								for any aspect of the linked website, including, but
								not limited to, its performance, access, data,
								information, quality, reliability of the services,
								any links available in the website and/or any of its
								contents in general. In any case, the establishment
								of any kind of hyperlink in the Private Portal to
								another website does not imply any type of
								relationship, collaboration or dependency between Us
								and the owners of the third-party website. <br />
								<br />
								<br />
								<span>
									4.3. LINKS TO THE PRIVATE PORTAL FROM OTHER
									WEBSITES
								</span>{" "}
								<br />
								<br />
								We do not allow introducing links to the Private
								Portal in websites that include content or
								information that is in any way illicit, degrading,
								or obscene, or that in general contravene the law,
								morality, public order or generally accepted social
								standards. We have no control nor human or technical
								resources to access or approve the information,
								contents and services provided by other websites
								that may establish links to our Private Portal. We
								shall not be liable for any aspect related to the
								website that contains the link, including, but not
								limited to, aspects relating to its operation,
								access, data, information, reliance and quality of
								its services, its own links and/or any of its
								contents in general. The existence of any links to
								the Private Portal does not imply any type of
								relationship, collaboration or dependency between Us
								and the owners of the third-party website. <br />
								<br />
								<br />
								<span>
									5. INTELLECTUAL PROPERTY RIGHTS OVER THE PRIVATE
									PORTAL{" "}
								</span>{" "}
								<br />
								<br />
								All intellectual and industrial property rights
								(including under patent law, copyright law, trade
								secret law, trade mark law, and all other
								proprietary rights) over the Private Portal ,
								including but not limited to, the Private Portal
								itself and any texts, photographs or illustrations,
								logos, brands, graphs, designs, interfaces or any
								other information, resource, content or service
								available through the Private Portal are owned by Us
								or have been licensed to Us accordingly by their
								corresponding owner for their display and/or
								availability on the Private Portal. Your
								intellectual and industrial property rights over the
								Services or the Private Portal shall be limited as
								follows: Services: The license over third-party API
								Services will be governed by the relevant terms and
								conditions applicable to each of the API Services as
								established by the PagoNxt entity who renders the
								Services. Private Portal: We only grant You a
								limited, non-exclusive, non-transferable,
								non-sublicensable and revocable license of the
								intellectual property rights over the Private Portal
								to access and use the Private Portal for Your
								internal business purpose and without commercial
								use. Nothwithstanding the above and unless otherwise
								authorized, Your access to and use of the Private
								Portal shall in no case involve any waiver,
								transmission, license or transfer, whether in whole
								or in part, of any intellectual and industrial
								property rights. We expressly reserve all our
								respective intellectual and industrial property
								rights over the Private Portal and its contents and
								Services. Particularly, We do not allow
								modification, copy, reproduction, communication,
								transformation or distribution in any media or form,
								in whole or in part, of the Private Portal or any of
								its content for any public or commercial purpose,
								except under our prior, explicit and written
								authorisation. Moreover, it is forbidden to remove
								or manipulate copyright indications or any other
								credit that may identify Private Portal lawful
								content holders, as well as technical protection
								devices, digital prints or any protective mechanism
								or information incorporated to the Private Portal.
								In the event that You submit or send Us information
								of any kind through the Private Portal, You
								acknowledge, warrant and accept that You have the
								necessary rights to do so, and that such information
								does not infringe intellectual and industrial
								property rights of any third party, and that such
								information is not confidential or detrimental to
								third parties. In this sense, You grant Us a license
								to use the information provided through the Private
								Portal, as necessary in order to provide You the
								requested services and/or contents. <br />
								<br />
								<br />
								<span>6. LIMITATION OF LIABILITY</span> <br />
								<br />
								The Private Portal and API Services, including
								without limitation the technology forming part of
								it, to the fullest extent permitted under applicable
								law, are provided on an “as is,” “where is” and
								“where available” basis, without any warranties or
								conditions of any kind, whether express or implied,
								statutory or otherwise. We disclaim all warranties
								and conditions with respect to the Private Portal or
								API Services including but not limited to warranties
								and conditions of satisfactory quality,
								merchantability, fitness for a particular purpose,
								non-infringement, title and/or availability. We do
								not guarantee that the Private Portal and API
								Services are or will remain updated, uninterrupted,
								complete, correct and/or secure. Third parties may
								make unauthorised alterations to the Private Portal
								and Services. If You become aware of any such
								alteration, contact us at
								payments.support@pagonxt.com and provide us with a
								description of such alteration and its location on
								the Private Portal. To the fullest extent permitted
								under applicable law: (i) we will not be liable for
								damages of any kind; (ii) without limiting the
								foregoing, we will not be liable for damages of any
								kind resulting from Your use of or inability to use
								the Private Portal or the API Services, including
								from any virus that may be transmitted in connection
								therewith; (c) Your sole and exclusive remedy for
								dissatisfaction with the Private Portal or the API
								Services is to stop using the Private Portal and the
								Services; and (d) our maximum aggregate liability
								for all damages, losses and causes of action,
								whether in contract, tort (including negligence) or
								otherwise, shall be fifty euros (EUR50). IF YOU ARE
								ACTING AS A CONSUMER, i.e., for purposes which are
								outside your trade, craft, business or profession,
								the following two paragraphs fully replace the
								foregoing three paragraphs of this Section
								(Limitation on Liability): “Nothing in these Terms
								shall have the effect of excluding or limiting our
								legal liability under applicable law in the event of
								(i) death or personal injury; (ii) fraudulent
								misrepresentation or fraudulent concealment of
								defects of the Private Portal and the API Services;
								(iii) loss and futile expenses caused by willful
								intent or gross negligence; (iv) a breach of a
								contractual guarantee as to the quality of the
								Private Portal and the API Services (if any); or (v)
								other cases where liability cannot be excluded or
								limited by agreement in advance (such as e.g. under
								applicable product liability laws). Subject to the
								foregoing sentence only: We will not be liable for
								your loss and expenses (jointly the “Damages”)
								caused without any negligence or intent on our part;
								We will not be liable for your Damages caused by the
								breach of a non-material contractual obligation
								caused with only slight negligence; Our liability
								for the breach of material contractual obligations
								with only slight negligence will be limited to those
								Damages foreseeable to the parties at the time of
								the conclusion of the agreement between You and us
								that typically arise in transactions of this kind.
								For this purpose, a material contractual obligation
								is deemed an obligation, the fulfilment of which is
								a prerequisite for enabling the proper fulfilment of
								the contract and on the fulfilment of which You may
								generally rely. Where we provide the Private Portal
								to You free of charge, We will not be liable for
								Damages caused with only slight negligence. If you
								are acting as a consumer, i.e., for purposes which
								are outside your trade, craft, business or
								profession, your use of the Private Portal and the
								API Services may be covered by warranties against
								defects under applicable law. Nothing in these Terms
								limits or excludes your statutory warranty rights
								and claims.” All limitations of liability of any
								kind in these Terms are made for the benefit of us
								and our affiliates and their respective
								shareholders, directors, officers, employees,
								affiliates, agents, representatives, licensors, and
								service providers (collectively, the “Affiliated
								Entities”), and our and their respective successors
								and assigns. We do not exclude or limit in any way
								our liability to You where it would be unlawful to
								do so. To the fullest extent permitted under
								applicable law, You agree to defend, indemnify and
								hold harmless us, the Affiliated Entities and their
								respective successors and assigns, from and against
								all claims, liabilities, damages, judgments, awards,
								losses, costs, expenses and fees (including
								attorneys’ fees) (“Claims”) arising out of or
								relating to (i) Your use of, or activities in
								connection with the Private Portal and the API
								Services; and (ii) any violation or alleged
								violation of these Terms by You (except for Claims
								caused by an act or omission of us or our Affiliated
								Entities). <br />
								<br />
								<br />
								<span>7. WARRANTIES BY US</span> <br />
								<br />
								We cannot warrant the reliability, usefulness and
								veracity of all the information, services and/or
								content available on the Private Portal. As a
								consequence, We cannot warrant and shall not be
								liable for: The continuity of the contents and API
								Services available on the Private Portal; The
								absence of errors on the Private Portal and/or
								available API Services; The absence of virus and/or
								any other damaging components in the Private Portal
								or the server providing the Private Portal; The
								invulnerability of the Private Portal or the
								security measures adopted; The lack of usefulness or
								the malfunction of any of the contents of the
								Private Portal; The damages or losses caused by any
								person who violates the Terms, whether caused to
								themselves or to third parties. <br />
								<br />
								<br />
								<span>8. WARRANTIES BY YOU</span> <br />
								<br />
								You undertake to comply with the Terms and to always
								act in accordance with applicable legislation.
								Likewise, You will refrain from using the Private
								Portal in any way that may prevent, damage or
								deteriorate its normal operation, the assets or
								rights of PagoNxt, the rest of Users or, in general,
								any third party. You warrant and represent that: The
								information that You have provided is accurate, true
								and up to date, and that You are not, and will not,
								hide or provide false information regarding Your
								identity, and that You will not provide third party
								information without their explicit consent; You have
								a legitimate and lawful reason to access and use the
								Private Portal and the API Services; You will not
								perform acts that may harm Us and/or Our rights and
								interests over the Private Portal and the API
								Services; You will comply with these Terms and any
								other legal documents available on the Private
								Portal and/or made available to You through any
								means and all laws and regulations applicable to
								You; You will block your User account and inform Us
								as soon as possible if You suspect or have knowledge
								of an inappropriate, unauthorized or prohibited use
								by third parties of Your credentials, the Private
								Portal or any Services; If You are using Our Private
								Portal on behalf of a third-party (i.e., Your
								company), You have the necessary capacity to act on
								behalf of Your company and have obtained all
								necessary consents and approvals to disclose and/or
								use any data that You and/or Your application(s)
								collect, generate or use; During the term of these
								Terms, Your application(s) is not and shall not be
								identified or considered as a service addressed to
								minors and that You are not included in a website or
								online service addressed to minors; and Your
								electronic acceptance of these Terms, where
								applicable, is authentic and is an advance
								electronic signature from a duly authorized
								representative, being the signature valid for al
								purposes according to eIDAS Regulation (Regulation
								(EU) nº 910/2014 on electronic identification and
								trust services for electronic transactions in the
								internal market). <br />
								<br />
								<br />
								<span>
									9. UPDATES OR MODIFICATIONS OF THE TERMS, THE
									PRIVATE PORTAL OR THE SERVICES{" "}
								</span>{" "}
								<br />
								<br />
								We may update these Terms at any time and We will
								post the updated Terms on the Private Portal. They
								shall be in effect from the time of their
								publication until their total or partial
								modification. From then on, the modified Terms will
								come into force and apply to Your use of the Private
								Portal. We will notify You via email, or through the
								Private Portal, when there is a substantial
								modification of the Terms that majorly affects Your
								use of the Private Portal. The access and/or use of
								the Private Portal after these Terms have been
								applied and/or published imply Your acceptance of
								the same. Additionally, on occasion, particularly
								when necessary for any technical, security or
								business reasons or business continuity, We may
								perform changes in relation to the Private Portal
								and/or any API Services (“Modifications”). In such
								cases, the Modifications to the Private Portal
								and/or to an available API Services will be
								published on the Private Portal. You shall comply
								with any new requirements set by any Modification
								thereto. You acknowledge and accept that
								Modifications may adversely affect Your
								application(s), including but not limited to:
								Changes in the way Your application(s) communicates
								with any of the Services; Changes in the manner in
								which Your application requests, receives, accesses,
								displays, transmits and/or uses any Services;
								Changes to any of Our API Services related to a
								particular API or SDK. We shall not be liable to You
								or to any User for any damages or consequences of
								any kind arising from any Modification or from its
								unfavorable effects. <br />
								<br />
								<br />
								<span>10. TERMINATION</span> <br />
								<br />
								You may terminate these Terms by sending a notice of
								termination to us via e-mail to
								payments.support@pagonxt.com , prior notice of
								fifteen (15) working days. Termination of these
								Terms may not suppose the termination of the API
								Services you have hired or contracted under a Direct
								or Indirect Agreement, which will survive in case of
								termination of the Terms. To access the API Services
								once these Terms are terminated, please, contact
								payments.support @pagonxt.com. The termination of
								the API Services will be governed by the applicable
								terms and conditions. We may terminate these Terms
								by written notice to You: (i) on five (5) working
								days prior notice via e-mail to You for any reason;
								or (ii) immediately by notice via e-mail to You if
								You commit a material or persistent breach of these
								Terms which You fail to remedy (if remediable)
								within seven (7) natural days after the service of
								notice via e-mail requiring You to do so.
								Notwithstanding anything to the contrary in these
								Terms, no termination of these Terms will relieve
								any party of any of such party’s obligations or
								liabilities accrued prior to such termination.
								Termination of these Terms by us will be without
								prejudice to any other right or remedy that we may
								have, now or in the future. Upon termination for any
								reason: (i) all rights granted to You under these
								Terms shall immediately cease; (ii) we may, without
								liability to You or any third party, immediately
								deactivate or delete Your account, and all
								associated API Services, without any obligation to
								provide any further access to them and without any
								compensation of any kind; and (iii) You must
								immediately cease Your use of the Private Portal and
								the API Services. <br />
								<br />
								<br />
								<span>11. FORCE MAJEURE</span> <br />
								<br />
								We will not be liable or responsible for any failure
								to perform, or delay in performance of, any of our
								obligations under these Terms that is caused by any
								act or event beyond our reasonable control,
								including failure of public or private
								telecommunication networks (“Event Outside Our
								Control”). If an Event Outside Our Control takes
								place that affects the performance of our
								obligations under these Terms We will use our
								reasonable endeavours to find a solution by which
								our obligations under these Terms may be performed.{" "}
								<br />
								<br />
								<br />
								<span>12. CUSTOMER SUPPORT</span> <br />
								<br />
								As owners of the Private Portal, We offer You a
								customer service in which We will give due attention
								to all Your queries, complaints and suggestions in
								relation to the Private Portal. However, please note
								that, to the extent that Your query, request,
								complaint or suggestion refers to an API Service
								provided by a Direct Customer, We may need to refer
								it to the entity or We shall indicate You to contact
								the relevant entity for support. Nonetheless, we do
								not always have the capacity, means or control to
								provide You support regarding third-party resources
								or tools. You will be able to access the customer
								service through payments.support@pagonxt.com. Our
								customer service will respond to complaints or
								inquiries received in the shortest possible time
								and, in any case, within the deadline provided under
								applicable law, depending on the type of complaint
								or inquiry. Likewise, You can use our contact form
								to get in touch with Us in order to inform Us of the
								existence of contents within the Private Portal
								which You consider that are contrary to law, to
								these Terms and/or violate the legitimate rights of
								any third party. If You provide to us any ideas,
								proposals, suggestions or other materials
								(“Feedback”), You agree that such Feedback is not
								confidential, and that Your provision of such
								Feedback is free, unsolicited and without
								restriction, and does not place us under any
								fiduciary or other obligation. <br />
								<br />
								<br />
								<span>13. CONFIDENTIAL INFORMATION</span>
								<br />
								<br />
								You undertake that it shall not at any time
								reproduce, use, sell, licence, exhibit, publish or
								otherwise disclose to any person any proprietary,
								secret or private information obtained as a result
								of these Terms (“Confidential Information”), except
								as permitted in this Section. You shall not use the
								Confidential Information for any purpose other than
								to exercise Your rights and perform Your obligations
								under or in connection with these Terms.
								Confidential Information does not include, and the
								obligations under this shall not apply to,
								information that the receiving party can evidence:
								(a) is, or later becomes, publicly available through
								no act or default of the receiving party or any
								third party; (b) is rightfully in its possession
								prior to disclosure to receiving party by disclosing
								party; (c) is received in good faith by the
								receiving party from a third party, free of any
								obligation of confidentiality; or (d) is
								independently developed without use of the
								disclosing parties Confidential Information. <br />
								<br />
								<br />
								<span>14. MISCELANEA</span> <br />
								<br />
								Each of the provisions of these Terms operates
								separately. If any court or competent authority
								decides that any of them are unlawful or
								unenforceable, the remaining conditions will remain
								in full force and effect. We may transfer our rights
								and obligations under these Terms. You may only
								transfer Your rights or obligations under these
								Terms to another person if We agree in writing.
								These Terms do not, and shall not be construed to,
								create any partnership, joint venture,
								employer-employee, agency or franchisor-franchisee
								relationship between You and us. Any words following
								the terms: “including”, “include”, “in particular”
								or “for example” or any similar phrase shall be
								construed as illustrative and shall not limit the
								generality of the related general words. <br />
								<br />
								<br />
								<span>15. GOVERNING LAW AND JURISDICTION</span>{" "}
								<br />
								<br />
								This Private Portal, including Your use of the same,
								and particularly these Terms shall be subject to,
								interpreted and enforced in accordance with Spanish
								law. For all disputes, actions or claims that may
								arise regarding the interpretation and application
								of these Terms, You and We agree to submit to the
								jurisdiction of the Courts of the city of Madrid
								(Spain) with express and voluntary waiver to any
								other jurisdiction. If You are acting as a consumer,
								i.e., for purposes which are outside Your trade,
								craft, business or profession, the foregoing choice
								of forum and venue does not apply to You, and the
								forum for any disputes between You and us shall
								instead be determined by applicable law.
							</p>
						</div>

						<div className="terms-conditions__button">
							<ButtonComponent
								onClick={closeConditionsModal}
								text={"Accept"}
							/>
						</div>
					</div>
				</ModalComponent>

				<ModalComponent
					show={showPrivacyModal}
					onClose={closePrivacyModal}
					size={"xxl"}
				>
					<div className="terms-conditions">
						<div className="terms-conditions__header">
							<h3>Privacy Notice</h3>
						</div>

						<div className="terms-conditions__text">
							<p className="text-legal">
								<strong>1. WHO ARE WE?</strong>
								<br />
								PagoNxt Payments Services is a modern and scalable
								platform for end-to-end payments and messaging
								solutions (hereinafter "we" or "PagoNxt"). Our
								clients are companies and financial institutions
								(legal entities, hereinafter "the Client" or "the
								Clients").
							</p>

							<p className="text-legal">
								<strong>
									2. WHAT IS THE OBJECTIVE OF THIS POLICY?
								</strong>
								<br />
								The purpose of this privacy policy is to explain in
								a transparent, clear and simple way how we collect,
								process and protect your personal information and to
								ensure that PagoNxt does not trade in personal
								information and that it allows data subjects to
								handle it in accordance with applicable regulations.
							</p>

							<p className="text-legal">
								<strong>
									3. TO WHOM IS THIS POLICY ADDRESSED?
								</strong>
								<br />
								This policy is aimed at data subjects who are the
								holders of the personal information that PagoNxt
								processes. Specifically, we distinguish the
								following types of data subjects to which we will
								refer throughout this document:
								<br />
								<br />
								<strong>1. Users:</strong> These are the data
								subjects who browse our website.
								<br />
								<strong>2. Applicants:</strong> data subjects who
								request information through one of our contact
								channels.
								<br />
								<strong>3. Representatives:</strong> data subjects
								representing one of the companies or institutions
								that are customers of PagoNxt or are in the process
								of becoming so. This group includes the
								following:Legal representatives or
								representatives.Contact persons.Authorized persons.
								This being understood to mean those persons
								authorized by our clients to access the different
								portals that PagoNxt makes available to them such as
								the Developer Portal, the Quantum Portal, the
								Incident Portal or any others.
								<br />
								<strong>4. Developers:</strong> Developer Portal
								users.
								<br />
								<strong>5. Candidates:</strong> data subjects who
								have applied for one of PagoNxt’s job offers. The
								information regarding the processing of their data
								is collected at the end of this notice (here).
							</p>

							<p className="text-legal">
								<strong>AT A GLANCE: BASIC INFORMATION</strong>
								<br />
								To facilitate understanding, we include the most
								relevant information in the table below. You can
								find more detailed information in the following
								heading and contact our Data Protection Officer
								(DPD) for any clarification or additional queries by
								writing to{" "}
								<a href="mailto:privacy.trade@pagonxt.com">
									privacy.trade@pagonxt.com
								</a>{" "}
								or through any of our channels. .
							</p>

							<div>
								<p className="text-legal">
									<table
										style={{
											width: "100%",
											borderCollapse: "collapse",
											border: "2px solid #e0e0e0",
										}}
									>
										<tbody>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													WHO IS RESPONSIBLE FOR THE
													PROCESSING OF YOUR DATA?
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
													}}
												>
													<b>
														PagoNxt Payments Services
														S.L.
													</b>
													<br />
													<b>N.I.F.:</b> B-87959326
													<br />
													<b>Address:</b> Avenida de
													Cantabria 5 – Edifico Alhambra
													Pl 1, CP 28660, Boadilla del
													Monte (Madrid)
													<br />
													<b>Contact DPO:</b>{" "}
													<a href="mailto:privacy.trade@pagonxt.com">
														privacy.trade@pagonxt.com
													</a>
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													HOW DO WE USE YOUR DATA?
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													We process your data to; i) be
													able to contact you or your
													company if you have asked us for
													information, ii) to maintain
													commercial and legal relations
													with the company you represent
													and provide the contracted
													services, iii) to comply with
													our legal obligations, to send
													commercial communications to
													your company, iv) if you are a
													developer to allow you to use
													our APIS, v) to send you
													commercial communications if you
													have consent and vi) in the case
													of users of our website to,
													provided they have accepted its
													use, insert cookies with various
													functions.
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													WHAT LEGAL BASIS DO WE USE TO
													PROCESS YOUR DATA?
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#f4f4f4",
													}}
												>
													It depends on the processing,
													but the main legal bases are
													legitimate interest, compliance
													with legal obligations and
													consent.
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													WITH WHOM DO WE SHARE YOUR DATA?
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<b>
														We can share your data with:
													</b>
													<ul>
														<li>
															Authorities and courts
															in legally provided
															cases.
														</li>
														<li>
															Third parties providing
															services under a data
															processor agreement (per
															Article 28 of the GDPR).
														</li>
													</ul>
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													WHERE DO WE GET YOUR DATA FROM?
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#f4f4f4",
													}}
												>
													The data we process{" "}
													<b>
														may have been collected
														directly from you, may have
														been given to us by a third
														party or confirmed by the
														company for which you are
														proxy, contact
													</b>
													, they may be included in the
													information given to us by one
													of the companies of the
													Santander Group or PagoNxt of
													which your company is already a
													customer and whose communication
													has authorized.
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													WHAT ARE YOUR RIGHTS?
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													You can always write to us at
													privacy.trade@pagonxt.com or use
													any of our channels available on
													the private portal to exercise
													your rights of:
													<ul>
														<li>
															Access: Or what is the
															same, let us tell you
															what data we have about
															you and provide you with
															a copy of it.
														</li>
														<li>
															Rectification: That we
															correct the information
															we hold about you if it
															is incorrect.
														</li>
														<li>
															Cancellation or
															deletion: That we delete
															your data when it is no
															longer necessary or when
															we are processing it
															because you have given
															us your consent.
														</li>
														<li>
															Objection: When the
															processing of your data
															is based on a legitimate
															interest.
														</li>
														<li>
															Limitation of
															processing: For example,
															when you have challenged
															the accuracy of
															themselves
														</li>
														<li>
															Portability: Requesting
															that we communicate your
															data to a third party in
															a structured, commonly
															used and
															machine-readable format,
															wherever possible, or to
															yourself.
														</li>
														<li>
															Right not to be subject
															to automated decisions.
														</li>
													</ul>
													You can also file a claim with
													the AEPD at www.aepd.es
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													ADDITIONAL INFORMATION
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													You can find more detailed
													information in the second layer.
												</td>
											</tr>
										</tbody>
									</table>
								</p>
							</div>
							<p className="text-legal">
								DETAILED INFORMATION ABOUT PRIVACY (SECOND LAYER)
								<br />
								<br />
								<strong>1. DO I HAVE TO SHARE MY DATA?</strong>
								<br />
								Yes. PagoNxt requests data from you directly, for
								example, when you contact us to request information
								or when we request data from your company, such as
								proxies or contact persons authorized to use
								different portals. Providing this data is necessary
								for the purposes for which it is requested and
								therefore mandatory.
							</p>
							<p className="text-legal">
								<strong>
									2. WHY DO WE PROCESS YOUR DATA? WHAT LEGAL BASIS
									DO WE USE? WHAT DATA ARE THEY AND WHERE DO THEY
									COME FROM?
								</strong>
							</p>

							<div>
								<p className="text-legal">
									<table
										style={{
											width: "100%",
											borderCollapse: "collapse",
											border: "2px solid #e0e0e0",
										}}
									>
										<tbody>
											<tr>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													DATA SUBJECT
												</th>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													WHAT DATA DO WE PROCESS ABOUT
													YOU AND WHAT IS ITS ORIGIN?
												</th>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													WHAT DO WE PROCESS YOUR DATA
													FOR?
												</th>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													WHAT IS THE LEGAL BASIS OF THE
													PROCESSING?
												</th>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													User
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													We treat the information we
													receive through the use of
													cookies if you have accepted
													them. This information may
													include your IP or unique
													identifiers that, although they
													do not allow us to uniquely
													identify you without additional
													information, are considered
													personal data and originate from
													your navigation on our websites.
													More information can be found in
													our cookies policy.
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													Depending on the type of cookies
													you have accepted, we will use
													them to improve our website by
													analyzing your usage or
													personalizing it. More
													information can be found in our
													cookies policy.
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<b>Consent</b>
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Applicant
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													Depending on the means you use
													to contact us, we may process:
													<br />
													<ul>
														<li>Name and surname</li>
														<li>Email</li>
														<li>Phone</li>
														<li>
															Position in your company
														</li>
													</ul>
													The data is obtained directly
													from you.
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													We process the data to meet your
													request and to inform you about
													our products or services.
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<b>Legitimate interest</b> in
													responding to your proactive and
													express request for information.
													This is expected by you and does
													not infringe on your rights.
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Representative (includes
													proxies, contact persons, and
													authorized persons)
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													The data that we may process
													includes those listed below and
													may have been communicated by
													you directly, if, for example,
													you signed your company’s
													contract with us or registered
													with the Developer Portal, or by
													your company (when you provide
													us with a list of authorized
													persons).
													<ul>
														<li>Name and surname</li>
														<li>
															Professional location
															data (address, email,
															mobile, and post)
														</li>
														<li>NIF</li>
														<li>
															Any other information
															included in the
															documentation provided
															for client registration
															Access credentials
														</li>
														<li>Access credentials</li>
														<li>
															IP, time, and location
															of the device from which
															the services are
															accessed
														</li>
														<li>
															Voice in case of
															telephone recordings (in
															these cases, you will be
															previously informed of
															the recording)
														</li>
													</ul>
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<b>1)</b> Register our Client
													and maintain the contractual and
													commercial relationship with it.
													<br />
													<b>2)</b> Send communications
													regarding the contracted
													services.
													<br />
													<b>3)</b> Send commercial
													communications (directed to your
													company) about products similar
													to those already contracted.
													<br />
													<b>4)</b> Prevent and control
													the use of PagoNxt for illegal
													or unauthorized purposes.
													<br />
													<b>5)</b>Send commercial
													communications of our own or of
													third parties for which we have
													requested consent.
													<br />
													<b>6)</b> Send satisfaction
													surveys about the service.{" "}
													<br />
													<b>7)</b> Use voice recordings
													to evaluate service quality and
													as proof of instructions
													received or service provided, if
													necessary.
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<b>1) Legitimate interest</b> of
													both our Client and PagoNxt in
													maintaining a contractual and
													commercial relationship.
													<br />
													<b>2) Legitimate interest</b> in
													informing our client about
													products similar to those
													contracted. <br />
													<b>3) Legitimate interest</b> in
													ensuring that PagoNxt is not
													used for illicit purposes and
													managing any illegal conduct.
													<br />
													<b>4) Consent.</b>
													<br />
													<b>5) Legitimate interest</b> in
													knowing the degree of customer
													satisfaction and seeking
													improvements. <br />
													<b>6) Legitimate interest</b> in
													improving service quality and
													exercising our rights.
													<br />
												</td>
											</tr>
										</tbody>
									</table>
								</p>
							</div>

							<p className="text-legal">
								<strong>
									3. WITH WHOM CAN WE SHARE YOUR DATA?
								</strong>
							</p>

							<div>
								<p className="text-legal">
									<table
										style={{
											width: "100%",
											borderCollapse: "collapse",
											border: "2px solid #e0e0e0",
										}}
									>
										<tbody>
											<tr>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													TYPE OF INTERESTED
												</th>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													WITH WHOM CAN IT BE SHARED?
												</th>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													WHY CAN DATA BE SHARED?
												</th>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													ON WHAT LEGAL BASIS?
												</th>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Any interested party (users,
													applicants, representatives)
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<b>
														1.With third parties (data
														processors):
													</b>{" "}
													PagoNxt uses third parties in
													the framework of its activity.
													Some of these third parties may
													access the personal information
													processed by PagoNxt but will
													always do so under a data
													processing agreement and under
													the instructions of PagoNxt.
													These third parties may belong
													to sectors such as cloud
													services, technology,
													communications, etc.
													<br />
													<b>2.With the following:</b>
													<ul>
														<li>
															Public administrations
														</li>
														<li>Courts and courts</li>
														<li>
															State Security Forces
															and Corps
														</li>
													</ul>
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<b>1.</b> o provide services to
													PagoNxt.
													<br />
													<b>2.</b> To respond to the
													specific requests of the same
													always complying with the
													applicable regulations or to
													exercise our rights.
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<b>1.</b> This communication is
													always supported by a processing
													order that will comply with the
													requirements of art. 28 of the
													General Data Protection
													Regulation, treating the data in
													charge of the processing on
													behalf and following the
													instructions of PagoNxt.
													<br />
													<b>2.</b> Compliance with legal
													obligations and legitimate
													interest in exercising our
													rights.
												</td>
											</tr>
										</tbody>
									</table>
								</p>
							</div>

							<p className="text-legal">
								<strong>4. INTERNATIONAL DATA TRANSFERS</strong>
								<br />
								No international transfers are carried out outside
								the European Economic Area, except in the case of
								the incident portal that is located whose services
								are provided by a processor besieged in the United
								States that is certified under the Data Privacy
								Framework which provides it with adequate
								guarantees.
							</p>

							<p className="text-legal">
								<strong>
									5. HOW LONG WILL WE KEEP PERSONAL INFORMATION?
								</strong>
							</p>

							<div className="w-embed">
								<p className="text-legal">
									<table
										style={{
											width: "100%",
											borderCollapse: "collapse",
											border: "2px solid #e0e0e0",
										}}
									>
										<tbody>
											<tr>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													INTERESTED
												</th>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													CONSERVATION PERIOD
												</th>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													User
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													We treat the information we
													collect through the use of
													Cookies for the periods
													indicated in the cookie policy
													that you can review here
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Applicant
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													For the period necessary to
													respond to the inquiry or
													request for information. After
													that period, the data will be
													blocked for a period of 3 years
													and will be deleted. The 3 years
													are the maximum limitation
													period for actions in matters of
													data protection collected by the
													LOPDyGDD (Organic Law 3/2018).
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Representative
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													For the entire duration of the
													contractual relationship plus 5
													years as a general rule, unless
													there are longer applicable
													limitation periods, in which the
													data will be kept blocked. In
													cases where data is processed in
													order to comply with legal
													obligations, these will be kept
													for the period indicated
													therein.
												</td>
											</tr>
										</tbody>
									</table>
								</p>
							</div>

							<p className="text-legal">
								<strong>
									6. WHAT ARE MY RIGHTS AND HOW DO I EXERCISE
									THEM?
									<br />
									You have the following rights:
								</strong>
								<br />
								<br />· <strong>Access:</strong> in other words, we
								tell you what information we have about you.
								<br />· <strong>Rectification:</strong> we correct
								the information we hold about you if it is
								incorrect.
								<br />· <strong>
									Cancellation or deletion:
								</strong>{" "}
								we delete your data when they are no longer needed
								or when we are processing them because you have
								given us your consent.
								<br />· <strong>Opposition:</strong> when the
								processing of your data is based on a legitimate
								interest.
								<br />· <strong>
									Limitation of processing:
								</strong>{" "}
								for example, where you have challenged the accuracy
								of the data themselves.
								<br />· <strong>Portability:</strong> by requesting
								that we communicate your data to a third party in a
								structured, commonly used, machine-readable format,
								where possible, or to you.
								<br />·{" "}
								<strong>
									The right not to be subject to automated
									decisions:
								</strong>{" "}
								that we do not base a decision that has legal effect
								on you or significantly affects you on automated
								processing, with some exceptions.
								<br />
								You can also file a claim with the Spanish Data
								Protection Agency (AEPD) at{" "}
								<a
									href="https://www.aepd.es/"
									rel="noreferrer"
									target="_blank"
								>
									www.aepd.es
								</a>
								.
							</p>

							<p className="text-legal">
								<strong>7. HOW CAN I EXERCISE YOUR RIGHTS?</strong>
								<br />
								You can exercise your rights through your private
								portal, by writing an email to{" "}
								<a href="mailto:privacy.trade@pagonxt.com">
									privacy.trade@pagonxt.com
								</a>{" "}
								or by contacting us through any of our channels,
								indicating that this is a request to exercise your
								rights of GDPR. Remember if we need to confirm your
								identity we may ask you for some kind of
								documentation about it.
							</p>

							<p className="text-legal">
								<strong>
									8. WHAT SECURITY MEASURES DO WE APPLY?
								</strong>
								<br />
								At PagoNxt we are committed to security, and we
								apply technical and organizational measures aimed at
								guaranteeing the integrity, unavailability and
								confidentiality of your data.For example, all
								persons who can access personal information are
								subject to a contractual obligation of
								confidentiality, we use Data Lost Prevention (DLP)
								measures, encryption of information in transit and
								at rest, access control, establishment of secure
								passwords, etc.
							</p>

							<p className="text-legal">
								<strong>
									9. OTHER IMPORTANT INFORMATION <br />
									<br /> 9.1 ON THE COMMUNICATION OF PERSONAL
									INFORMATION OF A THIRD PARTY
								</strong>
								<br />
								As we have already explained in some cases the
								personal information, we collect is not received
								directly from you as a data subject, but rather from
								another person, that represents or provides services
								for our Client is the one that communicates to us
								that information or it is information that we access
								in the framework of the provision of our services.{" "}
								<br />
								If you are that person, you declare that you have
								sufficient authorization both to act on behalf of
								our Client and to communicate such personal
								information that, in addition, you guarantee that it
								is true, accurate, complete and up to date. In
								addition, on behalf of the Client you will inform
								the interested party of the communication and
								indicate that you can review this document on our
								website or, if applicable, show a physical copy of
								this document. <br />
								<br />
								<strong>
									9.2. ABOUT CHANGES TO THIS POLICY AND INQUIRIES
								</strong>{" "}
								If it is necessary for us to change the content of
								this privacy policy we will inform you of the
								relevant aspects indicating the date from which they
								will be applicable. We will also publish it on our
								website.
								<br />
							</p>

							<p className="text-legal">
								<strong>
									10. PRIVACY NOTICE CANDIDATES
									<br />
									10.1. ‍Purpose of the processing and legal basis{" "}
									<br />
								</strong>{" "}
								<br />
								<div className="w-embed">
									<table
										style={{
											width: "100%",
											borderCollapse: "collapse",
											border: "2px solid #e0e0e0",
										}}
									>
										<tbody>
											<tr>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												></th>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													Management of the selection
													process
												</th>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Purpose
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<ul>
														<li>
															We will process your
															personal data to
															evaluate your
															suitability for the job
															you have requested and
															to manage the selection
															process.
														</li>
														<li>
															Linked to this
															processing and in the
															event that you turn out
															to be the candidat@
															selected, we may carry
															out a series of
															pre-hiring checks that
															may include: <br />
															<ul>
																<li>
																	We request
																	information
																	related to your
																	working life
																	issued by
																	official bodies
																	in order to
																	carry out the
																	necessary checks
																	before hiring.
																</li>
																<li>
																	We carry out an
																	analysis to
																	verify the
																	reliability,
																	integrity and
																	honesty of the
																	canditat@ and to
																	be able to
																	comply with the
																	legal
																	requirements
																	that may apply
																	to us and with
																	the internal
																	policies of the
																	PagoNxt Group.
																</li>
															</ul>
														</li>
													</ul>
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Lawfulness
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<ul>
														<li>
															The legal basis for this
															processing is the
															execution of the
															contract or the
															application of
															pre-contractual
															measures.
														</li>
														<li>
															If we ask you for the
															working life issued by
															official bodies, the
															processing will be based
															on our legitimate
															interest in verifying
															the truthfulness and
															experience that you
															reflect in your request.
														</li>
														<li>
															The legal basis for
															integrity analysis,
															reliability and honesty
															of the candidat@ will be
															the legitimate interest
															of PagoNxt that is
															specified in that
															PagoNxt for the type of
															activity it carries out
															and for the fact of
															being part of a
															financial group must
															guarantee that the
															people who occupy
															certain positions and
															perform certain
															functions are the ones
															suitable considering the
															risks associated with
															these functions, this
															being expected by the
															candidates, without
															imbalancing their
															rights.
														</li>
													</ul>
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Types of data processed
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<ul>
														<li>
															Contact and
															identification data
															(name, surname, address,
															telephone numbers); date
															of birth, gender, work
															and educational history,
															and any other
															information contained in
															your CV.
														</li>
														<li>
															Data derived from
															participation in tests
															during the selection
															process (group dynamics,
															interviews, language
															tests, etc.).
														</li>
														<li>
															The legal basis for
															integrity analysis,
															reliability and honesty
															of the candidat@ will be
															the legitimate interest
															of PagoNxt that is
															specified in that
															PagoNxt for the type of
															activity it carries out
															and for the fact of
															being part of a
															financial group must
															guarantee that the
															people who occupy
															certain positions and
															perform certain
															functions are the ones
															suitable considering the
															risks associated with
															these functions, this
															being expected by the
															candidates, without
															imbalancing their
															rights.
														</li>
														<li>Working life.</li>
														<li>
															Data publicly
															communicated by you in
															your social media
															profiles.
														</li>
													</ul>
												</td>
											</tr>
										</tbody>
									</table>
								</div>{" "}
								<br />
								<div className="w-embed">
									<table
										style={{
											width: "100%",
											borderCollapse: "collapse",
											border: "2px solid #e0e0e0",
										}}
									>
										<tbody>
											<tr>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												></th>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													Resolution and management of
													consultations
												</th>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Purpose
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<ul>
														<li>
															Answer the queries you
															can make during the
															selection process and
															after it.
														</li>
													</ul>
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Lawfulness
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<ul>
														<li>
															Our legitimate interest
															in answering any
															questions that may arise
															during the selection
															process or subsequently.
														</li>
													</ul>
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Types of data processed
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<ul>
														<li>
															Identification and
															contact data (name,
															surname, email,
															telephone).
														</li>
														<li>
															Any data that may be
															included in the
															consultation.
														</li>
													</ul>
												</td>
											</tr>
										</tbody>
									</table>
								</div>{" "}
								<br />
								<div className="w-embed">
									<table
										style={{
											width: "100%",
											borderCollapse: "collapse",
											border: "2px solid #e0e0e0",
										}}
									>
										<tbody>
											<tr>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												></th>
												<th
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														textAlign: "left",
														backgroundColor: "#f4f4f4",
														fontWeight: "bold",
													}}
												>
													Candidate Examination
												</th>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Purpose
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<ul>
														<li>
															For the purpose of
															confirming the
															suitability of the job
															seeker, the data of the
															candidate selected for
															checking that do not
															appear on the official
															sanctions lists (in
															particular in accordance
															with EU Regulations
															2580/2001, 881/2002,
															753/2011) are processed.
														</li>
														<li>
															This verification is
															done through the
															"Worldcheck" tool before
															making a firm offer to
															the selected candidate.
															For this purpose,
															personal data is
															verified only once.
														</li>
													</ul>
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Lawfulness
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<ul>
														<li>
															Depending on the entity
															that carries out the
															processing, or the
															fulfillment of legal
															obligations or the
															legitimate interest in
															fulfilling the
															obligations of due
															diligence and avoiding
															the possible damages
															(including possible
															fines) that may arise
															from the hiring of
															sanctioned persons.
														</li>
													</ul>
												</td>
											</tr>
											<tr>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
														fontWeight: "bold",
													}}
												>
													Types of data processed
												</td>
												<td
													style={{
														padding: "12px 15px",
														border: "1px solid #e0e0e0",
														backgroundColor: "#ffffff",
													}}
												>
													<ul>
														<li>Name and surname</li>
														<li>Date of birth</li>
														<li>Nationality</li>
													</ul>
												</td>
											</tr>
										</tbody>
									</table>
								</div>{" "}
								<br />
								<strong>10.2. Purpose of processing:</strong>
								<br /> We can share your data with:
								<br />
								<br />
								<strong>1. With service providers</strong>
								<br /> We may share your personal information with
								companies that provide services to us and with whom
								we have entered into a data processing agreement in
								accordance with applicable law. They will process
								your data on our behalf and following our
								instructions.
								<br />
								<strong>2. International data transfers</strong>
								<br /> Your data is not transferred directly outside
								the EU. However, we utilise the services of the
								company Workday, which provides 24/7 incident
								support. This could lead to a limited and specific
								access to your personal data from the United States
								in exceptional circumstances. It should be noted
								that the USA does not offer the same level of data
								protection as the EU. In these instances, we have
								implemented robust safeguards in line with data
								protection regulations and have put in place
								additional measures to ensure the security of your
								data. Should you require further information, please
								do not hesitate to contact the relevant DPO.
								<br /> <br />
								<strong>
									10.3. How long do we keep your data?
								</strong>
								‍We will process your personal data for the duration
								of the selection process and, where appropriate, for
								the time necessary to handle any enquiries that may
								arise. Once the process is complete, we will retain
								your information for a maximum period of one year in
								case there are future opportunities that you may be
								interested in, unless you indicate otherwise (right
								of objection). After the aforementioned period,
								unless you expressly authorise us to retain the
								information for a longer period, we will proceed to
								block the information and delete it after the expiry
								of the period of action (five years).
								<br /> <br />
								<strong>10.4. Exercise of rights</strong> ‍You can
								exercise your rights of access, rectification,
								erasure, limitation of processing, portability,
								opposition and the right not to be subject to
								automated individual decisions including profiling,
								sending an email to mailbox{" "}
								<a href="mailto:privacy.trade@pagonxt.com">
									privacy.trade@pagonxt.com
								</a>{" "}
								as well as filing a claim with the Spanish Data
								Protection Agency at{" "}
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.aepd.es/"
								>
									www.aepd.es
								</a>
							</p>
						</div>

						<div className="terms-conditions__button">
							<ButtonComponent
								onClick={closePrivacyModal}
								text={"Accept"}
							/>
						</div>
					</div>
				</ModalComponent>
			</div>

		</>
	);
};

export default AddPasswordPage;
