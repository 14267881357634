import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGlobalVar } from "../../utils/GlobalContext";

import { error as showError, success as showSuccess } from "../../utils/NotificationsContainer";

import { requestModification } from "../../../services/ApiRequest";

import BackButtonComponent from "../../atoms/back-button-component/BackButtonComponent";
import RequestModificationTabContent from "../../organisms/request-modification-tab-content/RequestModificationTabContent";
import ModalComponent from "../../molecules/modal-component/ModalComponent";
import AdviceModal from "../../organisms/advice-modal/AdviceModal";

import { HOME, DETAILS_ORGANIZATION } from "../../utils/Routes";
import { SANDBOX, PRE, LIVE } from "../../utils/GlobalConsts";

import { deleteSub } from "../../../services/ApiSubscriptions";

const tabs = [SANDBOX, PRE, LIVE];

const RequestModification = ({ isClient = false }) => {
	const {
		accessToken,
		userEmail,
		nameOrganization,
		fetchOrganizationDetails,
		userData,
		setLoggedOut
	} = useGlobalVar();
	const location = useLocation();
	const navigate = useNavigate();

	const { env, appName, organization } = location.state || {};

	const [activeTab, setActiveTab] = useState(env ? env : SANDBOX);
	const [subscriptionAPIResponse, setsubscriptionAPIResponse] =
		useState(null);
	const [loading, setLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

  const routeToNavigate = isClient ? DETAILS_ORGANIZATION : HOME
  const navigationText = isClient ? 'Back to Organization details' : 'Back to APP list'

  const logout = useCallback(() => {
		setLoggedOut();
		navigate("/logout");
	}, [setLoggedOut, navigate]);

  	// BORRAMOS LA APP
	const handleDelete = async () => {
		const data = subscriptionAPIResponse.subscriptionStateList;

		const cleanedData = data.map(
			({ name, nameOrganization, ...rest }) => rest
		);

		const requestData = {
			name: appName,
			nameOrganization: nameOrganization,
			subscriptions: cleanedData,
		};

		try {
			await deleteSub(JSON.stringify(requestData), accessToken, userData.email);
			if (!isClient) showSuccess('Your delete request has been sent successfully');
			navigate(routeToNavigate);
		} catch (error) {
			if (!isClient) showError("generalError");
			console.error("Error deleting subscription:", error);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requestModification(
					nameOrganization,
					appName,
					accessToken
				);

				setsubscriptionAPIResponse(response);
			} catch (error) {
				if(error.response && error.response.status === 401){
					logout();
				}
				throw new Error(error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [accessToken, appName, nameOrganization, logout]);

  useEffect(() => {
		fetchOrganizationDetails(); // Proporciona el ID de la organización
	}, [fetchOrganizationDetails]);


	// SETEAMOS LA TAB ACTIVA CON LA RESPUESTA DEL SERVIDOR
	useEffect(() => {
		setActiveTab(env ? env : SANDBOX);
	}, [subscriptionAPIResponse, env]);

	if (loading) return <p>Loading...</p>;

	return (
		<>
			{subscriptionAPIResponse ? (
				<>
					<div className="request-modification">
						<div className="request-modification__heading">
							<div className="request-modification__heading__back-button">
								<BackButtonComponent text={navigationText}  url={routeToNavigate}/>
							</div>
							<div className="request-modification__heading__title">
								<h1>{appName}</h1>
							</div>
							<div className="request-subscription__heading__description">
								<p>
									You can subscribe to a sandbox to test the
									API in practice, and when you're ready,
									change your subscription to the live
									environment and let your app conquer the
									world.
								</p>
							</div>
						</div>

						{/* LISTADO DE TABS */}
						<div className="request-modification__tabs">
							{tabs.map((item, index) => (
								<li
									className={`request-modification__tabs__tab ${
										index === tabs.indexOf(activeTab)
											? "active"
											: ""
									}`}
									key={index}
									onClick={() => setActiveTab(item)}
								>
									{item}
								</li>
							))}
						</div>

						{/* PASAMOS LA RESPUESTA AL CONTENIDO DE LA TAB */}
						<RequestModificationTabContent
							subscriptionAPIResponse={subscriptionAPIResponse}
							currentEnv={activeTab}
							userClient={isClient ? true : userData.client}
							appName={appName}
              organization={(isClient && organization) ? organization : undefined}
						/>

						<div className="request-modification__delete">
							<div className="request-modification__delete__text">
								<div className="request-modification__delete__text__title">
									<h4>Delete registered APP</h4>
								</div>
								<div className="request-modification__delete__text__description">
									You will delete the registered application
									and associated information. You won't be
									able to undo this.
								</div>
							</div>
							<div
								className="request-modification__delete__button"
								onClick={openModal}
							>
								<div className="request-modification__delete__button__icon"></div>
								<div className="request-modification__delete__button__text">
									Delete registered APP
								</div>
							</div>
						</div>
					</div>

					<ModalComponent
						show={showModal}
						onClose={closeModal}
						size={"lg"}
					>
						<AdviceModal onClose={closeModal} del delFunction={handleDelete}>
							<div data-type="title">
								Do you want to delete{" "}
								{
									subscriptionAPIResponse
										?.subscriptionStateList[0]?.name
								}
								?
							</div>
							<div data-type="advice">
								Make sure you want to delete your APP.{" "}
								<span>
									After the action you will lose the
									subscription to the different APIs.
								</span>
							</div>
						</AdviceModal>
					</ModalComponent>

				</>
			) : (
				<>
					<p>Loading...</p>
				</>
			)}
		</>
	);
};

export default RequestModification;