import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useGlobalVar } from "../../../utils/GlobalContext";
import InputComponent from "../../../atoms/input-component/InputComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";

import {
	error as showError,
	success as showSuccess,
} from "../../../utils/NotificationsContainer";

import {
	getOrganizationsList,
	getOrganizationsTypes,
	getOrganizationsDetails,
	updateOrganization,
} from "../../../../services/ApiOrganization";

const EditOrganization = () => {
	const navigate = useNavigate();
	const { accessToken, setLoggedOut } = useGlobalVar();
	const [organizationDetails, setOrganizationDetails] = useState(null);
	const [organizationType, setOrganizationType] = useState([]);
	const [organizationList, setOrganizationList] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const currentOrganizationId = localStorage.getItem("currentOrganizationId");

	const [updateData, setUpdateData] = useState({
		name: "",
		codigoOrganization: "",
		email: "",
		contract: "",
		cif: "",
		clientOfClient: false,
		directClient: "",
		users: [
			{
				firstname: "",
				lastname: "",
				username: "",
				email: "",
				phone: "",
				rol: "ADMIN",
			},
		],
	});

	const logout = useCallback(() => {
		setLoggedOut();
		navigate("/logout");
	}, [navigate, setLoggedOut]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				const [
					organizationListData,
					typeOrganizationData,
					organizationDetailData,
				] = await Promise.all([
					getOrganizationsList(accessToken),
					getOrganizationsTypes(accessToken),
					getOrganizationsDetails(currentOrganizationId, accessToken),
				]);

				setOrganizationList(organizationListData);
				setOrganizationType(typeOrganizationData);
				setOrganizationDetails(organizationDetailData);

				if (organizationDetailData) {
					setUpdateData({
						name: organizationDetailData.organization.name || "",
						codigoOrganization:
							organizationDetailData.organization
								.codigoOrganization || "",
						email: organizationDetailData.organization.email || "",
						contract:
							organizationDetailData.organization.contract ===
							"true"
								? true
								: organizationDetailData.organization
										.contract === "false"
								? false
								: organizationDetailData.organization
										.contract === "Active"
								? true
								: organizationDetailData.organization
										.contract === "Inactive"
								? true
								: organizationDetailData.organization.contract,
						cif: organizationDetailData.organization.cif || "",
						clientOfClient:
							organizationDetailData.organization
								.clientOfClient || false,
						directClient:
							organizationDetailData.organization.directClient ||
							"",
						users:
							organizationDetailData.users.length > 0
								? [
										{
											firstname:
												organizationDetailData.users[0]
													.firstname || "",
											lastname:
												organizationDetailData.users[0]
													.lastname || "",
											username:
												organizationDetailData.users[0]
													.username || "",
											email:
												organizationDetailData.users[0]
													.email || "",
											phone:
												organizationDetailData.users[0]
													.phone || "",
											rol:
												organizationDetailData.users[0]
													.rol || "ADMIN",
										},
								  ]
								: [
										{
											firstname: "",
											lastname: "",
											username: "",
											email: "",
											phone: "",
											rol: "ADMIN",
										},
								  ],
					});
				}
			} catch (error) {
				if (error.response.status === 403) {
					logout();
				}
				console.error("Error fetching data:", error);
				setError("Error fetching data");
			} finally {
				setLoading(false);
			}
		};

		if (currentOrganizationId) {
			fetchData();
		}
	}, [accessToken, currentOrganizationId, logout]);

	const organizationOptions = organizationType.map((org) => ({
		label: org.description,
		value: org.codOrganization,
	}));

	const directOrganizations = organizationList
		.filter((org) => org.clientOfClient)
		.map((org) => ({
			label: org.nameOrganization,
			value: org.nameOrganization,
		}));

	const handleChange = (name, value) => {
		setUpdateData((prevUpdateData) => {
			if (name in prevUpdateData) {
				return { ...prevUpdateData, [name]: value };
			} else if (name in prevUpdateData.users[0]) {
				const updatedUsers = prevUpdateData.users.map((user, index) => {
					if (index === 0) {
						return { ...user, [name]: value };
					}
					return user;
				});
				return { ...prevUpdateData, users: updatedUsers };
			}
			return prevUpdateData;
		});
	};

	const handleUserChange = (index, name, value) => {
		if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				// Asegura que solo se acepten números y hasta 9 dígitos
				setUpdateData((prevFormData) => {
					const newUsers = prevFormData.users.map((user, i) =>
						i === index ? { ...user, [name]: value } : user
					);
					return {
						...prevFormData,
						users: newUsers,
					};
				});
			}
		} else {
			setUpdateData((prevFormData) => {
				const newUsers = prevFormData.users.map((user, i) =>
					i === index ? { ...user, [name]: value } : user
				);
				return {
					...prevFormData,
					users: newUsers,
				};
			});
		}
	};

	const editOrganization = () => {
		setIsEditing(true);
	};

	const handleSubmit = async (e) => {
		if (e) e.preventDefault();

		updateData.contract = updateData.contract ? "true" : "false";
		try {
			await updateOrganization(updateData, accessToken);

			localStorage.setItem("reloadOrgEdited", "true");
			window.location.reload();
			setIsEditing(false);
		} catch (error) {
			if (error.response.status === 403) {
				logout();
			}
			showError("generalError");
			console.error("Error updating organization:", error);
		}
	};

	useEffect(() => {
		const reloadOrgEdited = localStorage.getItem("reloadOrgEdited");

		if (reloadOrgEdited) {
			showSuccess("Organization successfully modified");
			localStorage.removeItem("reloadOrgEdited");
		}
	}, []);

	if (loading) return <div>Loading...</div>;
	if (error) return <div>{error}</div>;
	if (!organizationDetails) return <div>No organization data found</div>;

	return (
		<div className="edit-organization">
			<div className="edit-organization__heading">
				<div className="edit-organization__form">
					<div className="edit-organization__form__input__title p p--b-2 p--t-4">
						<h4>Organization information</h4>
					</div>
					<div className="edit-organization__information p p--b-2">
						<div className="create-organization__content">
							<div className="create-organization__form">
								<InputComponent
									type="text"
									name="name"
									label="Name"
									value={updateData.name}
									onChange={handleChange}
									disabled={!isEditing}
								/>
								<InputComponent
									type="select"
									name="codigoOrganization"
									label="Type of Organization"
									options={organizationOptions}
									value={updateData.codigoOrganization}
									onChange={handleChange}
									disabled={!isEditing}
									className={"mg mg--b-2"}
								/>
								<InputComponent
									type="email"
									name="email"
									label="Email"
									placeholder="Insert Organization email"
									value={updateData.email}
									onChange={handleChange}
									disabled={!isEditing}
								/>
								<InputComponent
									type="text"
									name="cif"
									label="CIF"
									placeholder="Insert Organization CIF"
									value={updateData.cif}
									onChange={handleChange}
									disabled
								/>
								<InputComponent
									type="toggle"
									name="clientOfClient"
									label="Indirect Client"
									value={updateData.clientOfClient}
									onChange={handleChange}
									disabled={!isEditing}
								/>
								{updateData.clientOfClient && (
									<InputComponent
										placeholder="Select client"
										type="select"
										name="directClient"
										label="Organization of which it is a client"
										options={directOrganizations}
										value={updateData.directClient}
										onChange={handleChange}
										disabled={!isEditing}
									/>
								)}
							</div>
							<InputComponent
								type="toggle"
								name="contract"
								label="Active Contract"
								value={updateData.contract}
								onChange={handleChange}
								disabled={!isEditing}
							/>
						</div>

						<DividerComponent />
						<div className="create-organization__form">
							<div className="request-subscription__form__input__title p p--b-2">
								<h4>Main Administrator Information</h4>
							</div>
							{updateData.users.map((user, index) => (
								<div key={index}>
									<div>
										<InputComponent
											type="text"
											name="firstname"
											label="Name"
											placeholder="Insert Administrator Name"
											value={user.firstname}
											onChange={(name, value) =>
												handleUserChange(
													index,
													name,
													value
												)
											}
											disabled
										/>
										<InputComponent
											type="text"
											name="lastname"
											label="Lastname"
											placeholder="Insert Administrator Last name"
											value={user.lastname}
											onChange={(name, value) =>
												handleUserChange(
													index,
													name,
													value
												)
											}
											disabled
										/>
										<InputComponent
											type="email"
											name="email"
											label="Email"
											placeholder="Insert Administrator email"
											value={user.email}
											onChange={(name, value) =>
												handleUserChange(
													index,
													name,
													value
												)
											}
											disabled
										/>
										<InputComponent
											type="tel"
											name="phone"
											label="Phone and country code (Optional)"
											placeholder="Insert Administrator phone"
											value={user.phone}
											onChange={(name, value) =>
												handleUserChange(
													index,
													name,
													value
												)
											}
											disabled
										/>
									</div>
								</div>
							))}
						</div>
						<DividerComponent />
					</div>
					<div className="create-organization__buttons">
						{!isEditing ? (
							<ButtonComponent
								text="Edit"
								onClick={editOrganization}
								color="button-component--neutral"
							/>
						) : (
							<div className="pair-buttons">
								<ButtonComponent
									text="Cancel"
									onClick={() => setIsEditing(false)}
									color="button-component--neutral"
								/>
								<ButtonComponent
									text="Apply Modification"
									onClick={handleSubmit}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditOrganization;
