import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
	error as showError,
	success as showSuccess,
} from "../../utils/NotificationsContainer";

import {
	updateUserOrganization,
	updatePassword,
} from "../../../services/ApiOrganization";

import { deleteUser } from "../../../services/ApiAuth";

import { useGlobalVar } from "../../utils/GlobalContext";

import InputComponent from "../../atoms/input-component/InputComponent";
import ButtonComponent from "../../atoms/button-component/ButtonComponent";
import BackButtonComponent from "../../atoms/back-button-component/BackButtonComponent";
import DividerComponent from "../../atoms/divider-component/DividerComponent";
import ModalComponent from "../../molecules/modal-component/ModalComponent";
import AlertComponent from "../../atoms/alert-component/alertComponent";

import { MANAGE_USERS } from "../../utils/Routes";

const EditUserForm = () => {
	const navigate = useNavigate();

	const { accessToken } = useGlobalVar();
	const location = useLocation();
	const { rowData } = location.state;

	const [isEditing, setIsEditing] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [formData, setFormData] = useState({
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
		rol: "",
		id: "",
	});

	useEffect(() => {
		if (rowData) {
			setFormData({
				firstname: rowData.firstname || "",
				lastname: rowData.lastname || "",
				email: rowData.email || "",
				phone: rowData.phone || "",
				rol: rowData.roles || "",
				id: rowData.id || "",
			});
		}
	}, [rowData]);

	const handleChange = (name, value) => {
		if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				// Asegura que solo se acepten números y hasta 9 dígitos
				setFormData({
					...formData,
					[name]: value,
				});
			}
		} else {
			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: value,
			}));
		}
	};

	const handleSelectorChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const toggleEdit = () => {
		setIsEditing(!isEditing);
	};

	const handleSubmit = async (e) => {
		if (e) e.preventDefault();

		const cleanedPayload = JSON.parse(JSON.stringify(formData));

		try {
			await updateUserOrganization(cleanedPayload, accessToken);
			setIsEditing(false);

			showSuccess("User successfully modified");
		} catch (error) {
			showError("generalError");
			console.error("Error updating organization:", error);
		}
	};

	const handleUpdatePassword = async (e) => {
		if (e) e.preventDefault();

		const payload = {
			email: formData.email,
		};
		try {
			await updatePassword(payload, accessToken);
			showSuccess("Your request has been sent successfully");
		} catch (error) {
			showError("generalError");
			console.error("Error updating organization:", error);
		}
	};

	const handleDelete = async (e) => {
		if (e) e.preventDefault();

		const payload = {
			email: formData.email,
		};
		try {
			await deleteUser(payload, accessToken);
			setIsEditing(false);

			showSuccess("User successfully deleted");
			navigate(MANAGE_USERS);
		} catch (error) {
			showError("generalError");
			console.error("Error updating organization:", error);
		}
	};

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	return (
		<>
			<div className="add-user-form">
				<div className="add-user-form__heading">
					<div className="add-user-form__heading__back-button">
						<BackButtonComponent text={"Back to User list"} />
					</div>
					
				</div>
				<div className="add-user-form__form">
					<form>
						<div className="add-user-form__form__content">
							<div className="add-user-form__form__content__title">
								<h3>User information</h3>
							</div>
							<div className="add-user-form__form__content__row">
								<InputComponent
									type="text"
									name="firstname"
									value={formData.firstname}
									label="Name"
									placeholder="Insert name"
									onChange={handleChange}
									disabled={!isEditing}
								/>
								<InputComponent
									type="text"
									name="lastname"
									value={formData.lastname}
									label="Lastname"
									placeholder="Insert lastname"
									onChange={handleChange}
									disabled={!isEditing}
								/>
							</div>
							<div className="add-user-form__form__content__row">
								<InputComponent
									type="email"
									name="email"
									value={formData.email}
									label="Email"
									placeholder="Insert email"
									onChange={handleChange}
									disabled={true}
								/>
								<InputComponent
									type="tel"
									name="phone"
									value={formData.phone}
									label="Phone and country code (Optional)"
									placeholder="Insert phone number with the country code"
									onChange={handleChange}
									disabled={!isEditing}
								/>
							</div>

							<div className="input-content p p--b-2">
								<label className="p p--b-1">Password</label>
								{!isEditing ? (
									<span className="data">
										***************
									</span>
								) : (
									<div className="user-settings__password">
										<ButtonComponent
											text={"Reset password by email"}
											color={"button-component--warning"}
											onClick={handleUpdatePassword}
										/>
										<span className="aclaration">
											We’ll send a link to reset it.
										</span>
									</div>
								)}
							</div>

							<div className="add-user-form__form__content__title">
								<h3>User rol</h3>
							</div>
							<div className="add-user-form__form__content__checkbox">
								<div className="input-content">
									<div className="input-content__radios">
										<input
											type="radio"
											value="DEVELOPER"
											name="rol"
											id="DEVELOPER"
											checked={
												formData.rol === "DEVELOPER"
											}
											onChange={handleSelectorChange}
											disabled={!isEditing}
										/>
										<label htmlFor="DEVELOPER">
											<div className="input-content__radios__radio">
												<div className="input-content__radios__radio__content">
													<span className="input-content__radios__radio__title developer">
														DEVELOPER
													</span>
													<span className="input-content__radios__radio__description">
														Role for whoever needs
														to manage the APIs
													</span>
												</div>
											</div>
										</label>

										<input
											type="radio"
											value="ADMIN"
											name="rol"
											id="ADMIN"
											checked={formData.rol === "ADMIN"}
											onChange={handleSelectorChange}
											disabled={!isEditing}
										/>
										<label htmlFor="ADMIN">
											<div className="input-content__radios__radio">
												<div className="input-content__radios__radio__content">
													<span className="input-content__radios__radio__title admin">
														ADMIN
													</span>
													<span className="input-content__radios__radio__description">
														With this role you can
														manage the company's
														users
													</span>
												</div>
											</div>
										</label>
									</div>
								</div>
							</div>

							<DividerComponent />

							<div>
								<div className="add-user__buttons">
									{!isEditing ? (
										<ButtonComponent
											text="Edit"
											onClick={toggleEdit}
											color="button-component--neutral"
										/>
									) : (
										<div className="pair-buttons">
											<ButtonComponent
												text="Cancel"
												onClick={() =>
													setIsEditing(false)
												}
												color="button-component--neutral"
											/>
											<ButtonComponent
												text="Apply Modification"
												onClick={handleSubmit}
											/>
										</div>
									)}
								</div>
							</div>

							<DividerComponent />

							<div className="request-subscription__form edit-users__remove">
								<div className="edit-users__remove--text">
									<h3 className="mg mg--b-2">
										Remove access
									</h3>
									<p>
										It will remove access permissions. This
										user will no longer be able to access
										their company information in PagoNxt
									</p>
								</div>
								<div className="edit-users__remove--btn">
									<span className="edit-users__icon"></span>
									<ButtonComponent
										text={"Remove Access"}
										onClick={openModal}
										bgColor={"button-component--remove"}
									/>
								</div>
							</div>
							<ModalComponent
								show={showModal}
								onClose={closeModal}
								size={"lg"}
							>
								<div className="modal-delete-user__header">
									<h2>Do you want to delete this user?</h2>
								</div>

								<div className="modal-delete-user__body">
									<AlertComponent
										text={`Will remove access permissions from ${formData.firstname} ${formData.lastname}`}
										icon={"icon-info"}
										bgColor={"bg--brand-warning-100"}
									/>
								</div>
								<div className="modal-delete-user__buttons">
									<div className="pair-buttons">
										<ButtonComponent
											text={"Cancel"}
											onClick={closeModal}
											color={"button-component--neutral"}
										/>
										<ButtonComponent
											text={"Remove access"}
											onClick={handleDelete}
											color={"button-component--danger"}
										/>
									</div>
								</div>
							</ModalComponent>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default EditUserForm;
