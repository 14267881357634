import React, { useState, useEffect } from "react";
import { useGlobalVar } from "../../utils/GlobalContext";
import { useNavigate } from "react-router-dom";

import {
  error as showError,
  success as showSuccess,
} from "../../utils/NotificationsContainer";

import DividerComponent from "../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../atoms/button-component/ButtonComponent";

/* Sub-components */
import RegisterAppSection from "./components/RegisterAppSection";
import SelectEnvironmentSection from "./components/SelectEnvironmentSection";
import SelectApiSection from "./components/SelectApiSection";
import EnvironmentDataSection from "./components/EnvironmentDataSection";
import NotificationsSection from "./components/NotificationsSection";

import { HOME, REQUEST_MODIFICATION } from "../../utils/Routes";
import { SANDBOX, PRE, LIVE } from "../../utils/GlobalConsts";
import { WITHOUT, PENDING } from "../../utils/GlobalConsts";

import { subscribeApp } from "../../../services/ApiSubscriptions";

// PLACEHOLDERS PARA INPUTS TEXT Y TEXTAREAS
const placeholders = {
  name: "Type name of APP",
  publicKey: "Insert your public Key",
  keyId: "Insert your Key ID",
  insertIssuer: "Insert your Key ID",
};

const FormRequestSubscription = ({ onOpenBecomeCustomerModal }) => {
  const {
    accessToken,
    userEmail,
    nameOrganization,
    fetchOrganizationDetails,
    userData,
    becomeCustomerSended,
  } = useGlobalVar();

  const navigate = useNavigate();

  const showPendingError = () => {
    showError("requestAlready");
  };

  // OBJETO QUE RECOGE LOS DATOS
  const [formData, setFormData] = useState({
    nameOrganization: nameOrganization,
    name: "",
    environment: SANDBOX, //SANDBOX, PRE, LIVE
    subscribeAPI: {
      apiIso: PENDING, //WITHOUT, PENDING
      apiRTran: WITHOUT,
      apiCustomer: WITHOUT,
    },
    publicKey: "",
    keyId: "",
    insertIssuer: "",
    statusReport: false,
    webhoot: false,
    nameSecurity: "",
    basicSecurity: false,
    basicSecurityParam: {
      user: "",
      pass: "",
      notificationUrl: "",
    },
    oauthSecurity: false,
    oauthParam: {
      clientSecret: "",
      clientID: "",
      notificationUrl: "",
      tokenUrl: "",
    },
    notSecurity: false,
    notSecurityParam: {
      tokenUrl: "",
    },
  });

  // EVENTO QUE RECOGE LOS DATOS Y LOS ASIGNA A FormData
  const handleChange = (e) => {
    // CAMBIAMOS EL VALOR DEL ENTORNO
    if (e.target.name === "environment") {
      const selectedEnv = e.target.value;

      // CREAMOS OBJETO PARA API BASANDONOS EN LA ELECCIÓN ACTUAL
      let newAPI = formData.subscribeAPI;

      // SI ELEGIMOS SANDBOX TODAS LAS APIS SON SELECCIONADAS
      if (selectedEnv === SANDBOX) {
        newAPI = {
          apiIso: PENDING,
          apiRTran: PENDING,
          apiCustomer: PENDING,
        };
      }

      // SI ES LIVE SETEAMOS EL ENTORNO A LIVE, SINO LIMPIAMOS LOS CAMPOS DE NOTIFICACIÓN
      if (selectedEnv === LIVE)
        setFormData({
          ...formData,
          environment: selectedEnv,
          subscribeAPI: newAPI,
        });
      else {
        setFormData({
          ...formData,
          environment: selectedEnv,
          subscribeAPI: newAPI,
          statusReport: false,
          webhoot: false,
          notSecurity: false,
          oauthSecurity: false,
          basicSecurity: false,
          nameSecurity: "",
          oauthParam: {
            clientSecret: "",
            clientID: "",
            notificationUrl: "",
            tokenUrl: "",
          },
          basicSecurityParam: {
            notificationUrl: "",
            pass: "",
            user: "",
          },
          notSecurityParam: {
            tokenUrl: "",
          },
        });
      }
    } else if (
      e.target.name === "notifications" ||
      e.target.name === "notifications_sec"
    ) {
      const selectedNotification = e.target.value;

      switch (selectedNotification) {
        case "statusReport":
          setFormData({
            ...formData,
            statusReport: true,
            webhoot: false,
          });
          break;
        case "webhoot":
          setFormData({
            ...formData,
            statusReport: false,
            webhoot: true,
          });
          break;
        case "notSecurity":
          setFormData({
            ...formData,
            notSecurity: true,
            oauthSecurity: false,
            basicSecurity: false,
            nameSecurity: selectedNotification,
            oauthParam: {
              clientSecret: "",
              clientID: "",
              notificationUrl: "",
              tokenUrl: "",
            },
            basicSecurityParam: {
              notificationUrl: "",
              pass: "",
              user: "",
            },
          });
          break;
        case "basicSecurity":
          setFormData({
            ...formData,
            notSecurity: false,
            oauthSecurity: false,
            basicSecurity: true,
            nameSecurity: selectedNotification,
            oauthParam: {
              clientSecret: "",
              clientID: "",
              notificationUrl: "",
              tokenUrl: "",
            },
            notSecurityParam: {
              tokenUrl: "",
            },
          });
          break;
        case "oauthSecurity":
          setFormData({
            ...formData,
            notSecurity: false,
            oauthSecurity: true,
            basicSecurity: false,
            nameSecurity: selectedNotification,
            notSecurityParam: {
              tokenUrl: "",
            },
            basicSecurityParam: {
              notificationUrl: "",
              pass: "",
              user: "",
            },
          });
          break;
        default:
          break;
      }
    } else {
      if (e.target.type === "checkbox") {
        const { name, checked } = e.target;

        let checkedStatus = checked ? PENDING : WITHOUT;

        setFormData({
          ...formData,
          subscribeAPI: {
            ...formData.subscribeAPI,
            [name]: checkedStatus,
          },
        });
      } else {
        const { name, value } = e.target;
        setFormData((prevFormData) => {
          const keys = name.split(".");
          let newFormData = { ...prevFormData };
          let nested = newFormData;

          for (let i = 0; i < keys.length - 1; i++) {
            nested = nested[keys[i]];
          }

          nested[keys[keys.length - 1]] = value;

          return newFormData;
        });
      }
    }
  };

  // ENVIAR LOS DATOS A ENDPOINT
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      nameOrganization: formData.nameOrganization,
      name: formData.name,
      environment: formData.environment,
      subscribeAPI: formData.subscribeAPI,
      publicKey:
        formData.environment !== SANDBOX ? formData.publicKey : undefined,
      keyId: formData.environment !== SANDBOX ? formData.keyId : undefined,
      insertIssuer:
        formData.environment !== SANDBOX ? formData.insertIssuer : undefined,
      statusReport:
        formData.environment === LIVE ? formData.statusReport : undefined,
      webhoot: formData.environment === LIVE ? formData.webhoot : undefined,
      nameSecurity:
        formData.environment === LIVE ? formData.nameSecurity : undefined,
      basicSecurity:
        formData.environment === LIVE
          ? formData.basicSecurity
            ? formData.basicSecurity
            : undefined
          : undefined,
      basicSecurityParam:
        formData.environment === LIVE
          ? formData.basicSecurity
            ? formData.basicSecurityParam
            : undefined
          : undefined,
      oauthSecurity:
        formData.environment === LIVE
          ? formData.oauthSecurity
            ? formData.oauthSecurity
            : undefined
          : undefined,
      oauthParam:
        formData.environment === LIVE
          ? formData.oauthSecurity
            ? formData.oauthParam
            : undefined
          : undefined,
      notSecurity:
        formData.environment === LIVE
          ? formData.notSecurity
            ? formData.notSecurity
            : undefined
          : undefined,
      notSecurityParam:
        formData.environment === LIVE
          ? formData.notSecurity
            ? formData.notSecurityParam
            : undefined
          : undefined,
    };

    const cleanedPayload = JSON.parse(JSON.stringify(payload));

    // CONFIGURACIÓN DEL HEADER
    // const config = {
    // 	headers: {
    // 		"Content-Type": "application/json",
    // 		email: userEmail,
    // 		Authorization: accessToken,
    // 	},
    // };

    if (cleanedPayload.name === "") {
      showError("appNameMissing");
    } else {
      subscribeApp(cleanedPayload, accessToken, userData.email)
        .then((response) => {
          // localStorage.setItem('subscriptionAPIResponse', JSON.stringify(formData));

          // SUSCRIBIMOS TAMBIÉN A SANDBOX SI SE SUSCRIBE A PRE O LIVE
          if (formData.environment === PRE || formData.environment === LIVE) {
            const secondPayload = {
              nameOrganization: formData.nameOrganization,
              name: formData.name,
              environment: "SANDBOX",
              subscribeAPI: {
                apiIso: "PENDING",
                apiRTran: "PENDING",
                apiCustomer: "PENDING",
              },
            };

            // Realiza la segunda petición con los nuevos datos
            return subscribeApp(secondPayload, accessToken, userData.email).then(
              (secondResponse) => {
                showSuccess(
                  `Your subscription in ${payload.environment} has been registered`
                );
                // Navega a la siguiente página
                navigate(REQUEST_MODIFICATION, {
                  state: {
                    env: formData.environment,
                    appName: formData.name,
                    subscriptionAPIResponse: formData,
                  },
                });
              }
            );
          }

          // Este then se ejecuta cuando no es necesario hacer la segunda petición
          showSuccess(
            `Your subscription in ${payload.environment} has been registered`
          );

          // Navega a la siguiente página
          navigate(REQUEST_MODIFICATION, {
            state: {
              env: formData.environment,
              appName: formData.name,
              subscriptionAPIResponse: formData,
            },
          });
        })
        .catch((error) => {
          showError("generalError");
          console.error("Hubo un error al enviar el formulario:", error);
        });
    }
  };

  useEffect(() => {
    fetchOrganizationDetails(); // Proporciona el ID de la organización
  }, [fetchOrganizationDetails]);

  return (
    <div className="request-subscription__form">
      <form onSubmit={handleSubmit}>
        {/* PASO 1 - REGISTRO DE NOMBRE DE APP */}
        <RegisterAppSection
          formData={formData}
          placeholders={placeholders}
          handleChange={handleChange}
        />

        <DividerComponent />

        {/* PASO 2 - SELECCIÓN DE ENTORNO */}
        <SelectEnvironmentSection
          formData={formData}
          userData={userData}
          handleChange={handleChange}
          becomeCustomerSended={becomeCustomerSended}
          onOpenBecomeCustomerModal={onOpenBecomeCustomerModal}
          showPendingError={showPendingError}
        />

        <DividerComponent />

        {/* PASO 3 - SELECCIÓN DE API */}
        <SelectApiSection formData={formData} handleChange={handleChange} />

        {/* PASO 4 (OPCIONAL) DATOS DEL ENTORNO - SOLO HABILITADO SI EL ENTORNO ES PRE O LIVE */}
        {(formData.environment === PRE || formData.environment === LIVE) && (
          <>
            <DividerComponent />

            <EnvironmentDataSection
              formData={formData}
              placeholders={placeholders}
              handleChange={handleChange}
            />
          </>
        )}

        {/* PASO 5 (OPCIONAL) SELECCIÓN DE NOTIFICACIONES - SOLO HABILITADO SI EL ENTORNO ES LIVE */}
        {formData.environment === LIVE && (
          <>
            <DividerComponent />

            <NotificationsSection
              formData={formData}
              handleChange={handleChange}
            />
          </>
        )}

        <DividerComponent />

        <div className="request-subscription__form__submit-buttons">
          <div className="request-subscription__form__submit-buttons__cancel">
            <ButtonComponent
              text={"Cancel"}
              color={"button-component--neutral"}
              url={HOME}
            />
          </div>
          <div className="request-subscription__form__submit-buttons__confirm">
            <ButtonComponent type="submit" text={"Subscription API"} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormRequestSubscription;
