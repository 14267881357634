import React, { useEffect, useState } from "react";
import { useGlobalVar } from "../../utils/GlobalContext";

import BackButtonComponent from "../../atoms/back-button-component/BackButtonComponent";
import ButtonComponent from "../../atoms/button-component/ButtonComponent";
import DividerComponent from "../../atoms/divider-component/DividerComponent";
import InputComponent from "../../atoms/input-component/InputComponent";

import {
	error as showError,
	success as showSuccess,
} from "../../utils/NotificationsContainer";

import {
	updateUserOrganization,
	updatePassword,
} from "../../../services/ApiOrganization";

const UserSettings = () => {
	const { userData, nameOrganization, accessToken, fetchUserData } = useGlobalVar();
	const [isEditing, setIsEditing] = useState(false);

	const [updateData, setUpdateData] = useState({
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
		role: "",
		id: "",
	});

	useEffect(() => {
		if (userData) {
			// Aquí se setean los datos de userData en updateData
			setUpdateData({
				firstname: userData.firstname || "",
				lastname: userData.lastname || "",
				email: userData.email || "",
				phone: userData.phone || "",
				role: userData.roles ? userData.roles[0].name : "",
				id: userData.id,
			});
		}
	}, [userData]);

	const toggleEdit = () => {
		setIsEditing(!isEditing);
	};

	const handleInputChange = (name, value) => {
		if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				// Asegura que solo se acepten números y hasta 9 dígitos
				setUpdateData({
					...updateData,
					[name]: value,
				});
			}
		} else {
			setUpdateData((prevFormData) => ({
				...prevFormData,
				[name]: value,
			}));
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				// Asegura que solo se acepten números y hasta 9 dígitos
				setUpdateData((prevUpdateData) => ({
					...prevUpdateData,
					[name]: value,
				}));
			}
		} else {
			setUpdateData((prevUpdateData) => ({
				...prevUpdateData,
				[name]: value,
			}));
		}
	};

	const handleSubmit = async (e) => {
		if (e) e.preventDefault();
	  
		const cleanedPayload = JSON.parse(JSON.stringify(updateData));
		let success = false;
	  
		try {
		  await updateUserOrganization(cleanedPayload, accessToken);
		  setIsEditing(false);
		  await fetchUserData();
		  success = true; // Indica que todo en el try se ejecutó correctamente
		} catch (error) {
		  showError("generalError");
		  console.error("Error updating organization:", error);
		}
	  
		// Ejecuta las líneas solo si no hubo errores
		if (success) {
		  localStorage.setItem("reload", "true");
		  window.location.reload();
		}
	  };

	const handleUpdatePassword = async (e) => {
		if (e) e.preventDefault();

		const payload = {
			email: userData.email,
		};
		try {
			await updatePassword(payload, accessToken);
			setIsEditing(false);
			showSuccess("Your request has been sent successfully");
		} catch (error) {
			showError("generalError");
			console.error("Error updating password:", error);
		}
	};

	useEffect(() => {
		const reloaded = localStorage.getItem("reload");
		
		if (reloaded) {
			showSuccess("Your request has been sent successfully");
			localStorage.removeItem("reload");
		} 
	});

	return (
		<>
			<div className="request-subscription user-settings">
				<div className="request-subscription__heading">
					<div className="request-subscription__heading__back-button">
						<BackButtonComponent />
					</div>
					<div className="request-subscription__heading__title">
						<h1>User Settings</h1>
					</div>
				</div>
				<div className="request-subscription__form">
					<div className="request-subscription__form__input">
						<div className="request-subscription__form__input__title p p--b-2">
							<h4>User information</h4>
						</div>
						<div className="input-content p p--b-2">
							{!isEditing ? (
								<InputComponent
									type="text"
									name="firstname"
									label="Name"
									value={updateData.firstname}
									onChange={handleChange}
									disabled={!isEditing}
								/>
							) : (
								<div className="request-subscription__form__input__user">
									<div className="input-content">
										<div className="input-content__title">
											<span>Name</span>
										</div>
										<label>
											<input
												type="text"
												name="firstname"
												value={updateData.firstname}
												placeholder={
													updateData.firstname
												}
												autoComplete="off"
												onChange={handleChange}
											/>
										</label>
									</div>
									<div className="input-content">
										<div className="input-content__title">
											<span>Lastname</span>
										</div>
										<label>
											<input
												type="text"
												name="lastname"
												value={updateData.lastname}
												placeholder={userData.lastname}
												autoComplete="off"
												onChange={handleChange}
											/>
										</label>
									</div>
								</div>
							)}
						</div>
						{!isEditing ? (
							<div className="flex-content">
								<div className="input-content p p--b-2">
									<label className="p p--b-1">Email</label>
									<span className="data">
										{userData.email}
									</span>
								</div>
								<div className="input-content p p--b-2">
									<label className="p p--b-1">Phone and country code (Optional)</label>
									<span className="data">
										{userData.phone}
									</span>
								</div>
							</div>
						) : (
							<div className="request-subscription__form__input__user p p--b-2">
								<div className="input-content">
									<div className="input-content__title">
										<span>Email</span>
									</div>
									<label>
										<input
											type="text"
											name="email"
											value={updateData.email}
											placeholder={userData.email}
											autoComplete="off"
											onChange={handleChange}
											disabled={true}
										/>
									</label>
								</div>
								<InputComponent
									type="tel"
									name="phone"
									value={updateData.phone}
									label="Phone and country code (Optional)"
									placeholder="Insert phone number with the country code"
									onChange={handleInputChange}
								/>
							</div>
						)}

						<div className="input-content p p--b-2">
							<label className="p p--b-1">Password</label>
							{!isEditing ? (
								<span className="data">***************</span>
							) : (
								<div className="user-settings__password">
									<ButtonComponent
										text={"Reset your password by email"}
										color={"button-component--warning"}
										onClick={handleUpdatePassword}
									/>
									<span className="aclaration">
										Need to change your password? We’ll send
										you a link to reset it.
									</span>
								</div>
							)}
						</div>
					</div>
					{/* <DividerComponent />
					<div className="request-subscription__form__input">
						<div className="request-subscription__form__input__title p p--b-2">
							<h4>Preferences</h4>
						</div>
						<div className="input-content  p p--b-2">
							<label className="p p--b-1">Language</label>
							{!isEditing ? (
								<span>English (UK)</span>
							) : (
								<div>
									<InputComponent
										type="select"
										name="language"
										options={[
											{
												label: "English (UK)",
												value: "english(UK)",
											},
										]}
									/>
								</div>
							)}
						</div>
						<div className="input-content">
							<label className=" p p--b-1">Appearance</label>
							{!isEditing ? (
								<span>Light mode</span>
							) : (
								<div>
									<InputComponent
										type="select"
										name="mode"
										options={[
											{
												label: "Light Mode",
												value: "light",
											},
										]}
									/>
								</div>
							)}
						</div>
					</div> */}
					<DividerComponent />
					{!isEditing ? (
						<ButtonComponent
							text={"Edit"}
							onClick={toggleEdit}
							color={"button-component--neutral"}
						/>
					) : (
						<div className="pair-buttons">
							<ButtonComponent
								text={"Cancel"}
								onClick={toggleEdit}
								color={"button-component--neutral"}
							/>
							<ButtonComponent
								text={"Apply modification"}
								onClick={handleSubmit}
							/>
						</div>
					)}
				</div>

				<div className="request-subscription__form">
					<div className="request-subscription__form__input">
						<div className="request-subscription__form__input__title p p--b-2">
							<h4>Organization</h4>
						</div>
						<div className="flex-content">
							<div className="input-content p p--b-2">
								<label className="p p--b-1">Name</label>
								<span className="data">{nameOrganization}</span>
							</div>
							<div className="input-content p p--b-2">
								<label className="p p--b-1">
									Type of organization
								</label>
								<span className="data">
									{userData.organizationTypeDescription}
								</span>
							</div>
							<div className="input-content p p--b-2">
								<label className="p p--b-1">CIF</label>
								<span className="data">
									{userData.organizationCif}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default UserSettings;
