import React, { useState, useEffect } from "react";
import { useGlobalVar } from "../../utils/GlobalContext";

import {
  error as showError,
  success as showSuccess,
} from "../../utils/NotificationsContainer";
import COUNTRIES from "../../data/countries";

import DividerComponent from "../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../atoms/button-component/ButtonComponent";
import InputComponent from "../../atoms/input-component/InputComponent";
import { getOrganizationsTypes } from "../../../services/ApiOrganization";

import { sendBecomeCustomerRequest } from "../../../services/ApiUsers";

// PLACEHOLDERS PARA INPUTS TEXT Y TEXTAREAS
const placeholders = {
  firstName: "Insert your phone number name",
  lastName: "Insert your last name",
  email: "Insert your Email",
  phone: "Insert your phone number with the country code",
  companyName: "Insert your company name",
  nif: "Insert your fiscal identity number",
};

const FormBecomeCustomer = ({ onClose }) => {
  const {
    accessToken,
    userEmail,
    nameOrganization,
    userData,
    fetchBecomeCustomerSended,
  } = useGlobalVar();

  const [loading, setLoading] = useState(true);
  const [organizationType, setOrganizationType] = useState([]);

  const [formData, setFormData] = useState({
    firstName: userData.firstname,
    lastName: userData.lastname,
    email: userData.email,
    phone: userData.phone,
    companyName: nameOrganization || "",
    nif: userData.cif || userData.organizationCif,
    organizationCode: "",
    country: "",
    countryIso: "",
    alreadyCustomer: false,
    nameOrganization: nameOrganization || "",
    mail: userData.email,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setOrganizationType(await getOrganizationsTypes(accessToken));
      } catch (error) {
        throw new Error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken]);

  const [files, setFiles] = useState([]);

  const handleInputChange = (name, value) => {
    if (name === "phone") {
      if (/^\d*$/.test(value) && value.length <= 15) {
        // Asegura que solo se acepten números y hasta 9 dígitos
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    if (type === "checkbox" && name === "alreadyCustomer") {
      setFormData({
        ...formData,
        [name]: checked, // Actualiza `alreadyCustomer` con el valor del checkbox
      });
    } else if (name === "phone") {
      if (/^\d*$/.test(value) && value.length <= 15) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else if (name === "countryIso") {
      const selectedCountryName = e.target.options[e.target.selectedIndex].text;
      setFormData({
        ...formData,
        countryIso: value,
        country: selectedCountryName,
      });
    } else {
      setFormData((prevFormData) => {
        const keys = name.split(".");
        let newFormData = { ...prevFormData };
        let nested = newFormData;

        for (let i = 0; i < keys.length - 1; i++) {
          nested = nested[keys[i]];
        }

        nested[keys[keys.length - 1]] = value;

        return newFormData;
      });
    }
  };

  const handleSelector = (name, value) => {
    setFormData((prevUpdateData) => {
      if (name in prevUpdateData) {
        return { ...prevUpdateData, [name]: value };
      }
      return prevUpdateData;
    });
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter(
      (file) => file.type === "application/pdf"
    );

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar el formulario
    if (formData.nif === "") {
      showError("addVat");
      return;
    } else if (files.length === 0) {
      showError("addDocuments");
      return;
    }

    try {
      // Llamar a la función desde apisubscription.api
      await sendBecomeCustomerRequest(formData, files, accessToken);

      fetchBecomeCustomerSended();
      showSuccess("Your upgrade plan request has been sent successfully");
      onClose();
    } catch (error) {
      if (error.response?.data?.statusCode === 429) {
        showError("requestAlready");
      } else {
        showError("generalError");
      }
      console.error("Error al enviar el formulario:", error);
    }
  };

  const organizationOptions = organizationType.map((org) => ({
    label: org.description,
    value: org.codOrganization,
  }));

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <div className="form-become-customer">
        <div className="form-become-customer__content">
          <div className="form-become-customer__content__header">
            <div className="form-become-customer__content__header__title">
              Upgrade your plan
            </div>
            <div className="form-become-customer__content__header__description">
              Boost your business by upgrading your PagoNxt Payments plan for
              enhanced features and continued growth
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="form-become-customer__content__form">
              <div className="form-become-customer__content__form__contact">
                <div className="form-become-customer__content__form__contact__title form-title">
                  Contact information
                </div>
                <div className="form-become-customer__content__form__contact__input">
                  <div className="input-content">
                    <div className="input-content__title">
                      <span>First Name</span>
                    </div>
                    <label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        placeholder={placeholders.firstName}
                        autoComplete="off"
                        onChange={handleChange}
                        disabled
                      />
                    </label>
                  </div>
                </div>
                <div className="form-become-customer__content__form__contact__input">
                  <div className="input-content">
                    <div className="input-content__title">
                      <span>Last Name</span>
                    </div>
                    <label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        placeholder={placeholders.lastName}
                        autoComplete="off"
                        onChange={handleChange}
                        disabled
                      />
                    </label>
                  </div>
                </div>
                <div className="form-become-customer__content__form__contact__input">
                  <div className="input-content">
                    <div className="input-content__title">
                      <span>Email</span>
                    </div>
                    <label>
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        placeholder={placeholders.email}
                        autoComplete="off"
                        onChange={handleChange}
                        disabled
                      />
                    </label>
                  </div>
                </div>
                <div className="form-become-customer__content__form__contact__input">
                  <InputComponent
                    type="tel"
                    name="phone"
                    label="Phone and country code"
                    value={formData.phone}
                    placeholder={placeholders.phone}
                    onChange={handleInputChange}
                  />
                  {/* {
										<div className="input-content">
											<div className="input-content__title">
												<span>Phone</span>
											</div>
											<label>
												<input
													type="tel"
													name="phone"
													value={formData.phone}
													placeholder={
														placeholders.phone
													}
													autoComplete="off"
													onChange={handleChange}
												/>
											</label>
										</div>
									} */}
                </div>
              </div>
              <div className="form-become-customer__content__form__company">
                <div className="form-become-customer__content__form__company__title form-title">
                  Company information
                </div>
                <div className="form-become-customer__content__form__company__input">
                  <div className="input-content">
                    <div className="input-content__title">
                      <span>Company name</span>
                    </div>
                    <label>
                      <input
                        type="text"
                        name="companyName"
                        value={formData.companyName}
                        placeholder={placeholders.companyName}
                        autoComplete="off"
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                </div>
                <div className="form-become-customer__content__form__contact__input">
                  <div className="input-content">
                    <div className="input-content__title">
                      <span>VAT number</span>
                    </div>
                    <label>
                      <input
                        type="text"
                        name="nif"
                        value={formData.nif}
                        placeholder={placeholders.nif}
                        autoComplete="off"
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                </div>

                <div className="form-become-customer__content__form__contact__input">
                  <InputComponent
                    type="select"
                    name="organizationCode"
                    label="Type of Organization"
                    options={organizationOptions}
                    value={formData.organizationCode}
                    onChange={handleSelector}
                    disabled={false}
                    placeholder="Select type"
                  />
                </div>
                <div className="form-become-customer__content__form__contact__input">
                  <div className="input-content">
                    <div className="input-content__selector">
                      <label htmlFor="countryIso">Country</label>
                      <select
                        name="countryIso"
                        id="countryIso"
                        onChange={handleChange}
                        value={formData.countryIso}
                      >
                        <option value="" disabled>
                          Location
                        </option>
                        {COUNTRIES &&
                          COUNTRIES.length > 0 &&
                          COUNTRIES.map(({ value, label }) => (
                            <option value={value}>{label}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-become-customer__content__form__contact__input">
                  <div className="input-content">
                    <div className="input-content__checkbox">
                      <label>
                        <input
                          type="checkbox"
                          name="alreadyCustomer"
                          onChange={handleChange}
                          checked={formData.alreadyCustomer}
                        />
                        <div className="checkmark">
                          <div className="check"></div>
                        </div>
                        <p>My company is already a PagoNxt Payments or Santander Bank client</p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DividerComponent />

            <div className="form-become-customer__content__docs">
              <div className="form-become-customer__content__docs__title">
                To process your request we need you to attach the following
                documents:
              </div>
              <div className="form-become-customer__content__docs__description">
                <span>• Non-client</span>: Company incorporation deed, Ownership
                title certificate <br />
                <span>• Already client</span>: Contract with PagoNxt Payments
              </div>
              <div className="form-become-customer__content__docs__bottom">
                <div className="form-become-customer__content__docs__bottom__add-button">
                  <div className="input-content">
                    <div className="input-content__files">
                      <label htmlFor="file">
                        <span>Attach your documents</span> (only PDF)
                      </label>
                      <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleFileChange}
                        accept=".pdf"
                      />
                    </div>
                  </div>
                </div>
                {files.length > 0 ? (
                  <div className="form-become-customer__content__docs__bottom__files">
                    <ul>
                      {files.map((file, index) => (
                        <li key={index}>
                          {file.name}{" "}
                          <span
                            onClick={() => handleRemoveFile(index)}
                            className="cross"
                          ></span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>

            <DividerComponent />

            <div className="form-become-customer__content__submit">
              <div className="form-support__form__submit__button">
                <ButtonComponent type="submit" text={"Send"} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormBecomeCustomer;
