import { React, useEffect, useState } from "react";
import {
	error as showError,
	success as showSuccess,
} from "../../utils/NotificationsContainer";
import { useGlobalVar } from "../../utils/GlobalContext";

import { getOrganizationsTypes } from "../../../services/ApiOrganization";
import { registerUser, registerUserAdmin } from "../../../services/ApiAuth";

import InputComponent from "../../atoms/input-component/InputComponent";
import ButtonComponent from "../../atoms/button-component/ButtonComponent";
import BackButtonComponent from "../../atoms/back-button-component/BackButtonComponent";
import DividerComponent from "../../atoms/divider-component/DividerComponent";

import { useNavigate } from "react-router-dom";

import { MANAGE_USERS } from "../../utils/Routes";

const AddUserForm = () => {
	const { accessToken, userOrganization, nameOrganization, userData } =
		useGlobalVar();

	const [userRol, setUserRol] = useState("DEVELOPER");
	const navigate = useNavigate();

	const [formData, setformData] = useState({
		username: "",
		firstname: "",
		lastname: "",
		phone: "",
		active: true,
		email: "",
		password: "",
		organizationName: nameOrganization,
		organizationCif: userOrganization,
		organizationType: "",
		userType: "",
		invited: true
	});

	useEffect(() => {
		setUserRol(formData.userType);
	}, [formData]);

	// useEffect(() => {
	// 	setformData((prevformData) => ({
	// 		...prevformData,
	// 		username: prevformData.email,
	// 	}));
	// }, [formData.email]);

	// const handleChange = (name, value) => {
	// 	if (name === "rol") {
	// 		setUserRol(value);
	// 	} else {
	// 		setformData((prevformData) => ({
	// 			...prevformData,
	// 			[name]: value,
	// 		}));
	// 	}
	// };

	const handleChange = (name, value) => {
		if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				// Asegura que solo se acepten números y hasta 9 dígitos
				setformData({
					...formData,
					[name]: value,
				});
			}
		} else {
			setformData((prevFormData) => ({
				...prevFormData,
				[name]: value,
			}));
		}
	};

	const handleSelectorChange = (event) => {
		const { name, value } = event.target;
		setformData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		if (e) e.preventDefault();

		try {
			// Realiza la llamada GET para obtener los tipos de organización
			const organizationTypes = await getOrganizationsTypes(accessToken);

			// Filtra el tipo de organización según userData.organizationTypeDescription
			const filteredOrganization = organizationTypes.find(
				(organization) =>
					organization.name_organization ===
					userData.organizationTypeName
			);

			if (!filteredOrganization) {
				showError("resumitYourRequest");
				return;
			}

			const codOrganization = filteredOrganization.codOrganization;

			// Crea el payload con los datos del formulario
			const dataToSubmit = {
				...formData,
				userRol, // Añadimos el rol seleccionado
			};

			dataToSubmit.organizationType = codOrganization;

			const cleanedPayload = JSON.parse(JSON.stringify(dataToSubmit));
			cleanedPayload.username = cleanedPayload.email;
			cleanedPayload.client = true;

			// Aquí puedes continuar con tu lógica de envío, dependiendo del rol del usuario
			if (userRol === "DEVELOPER") {
				try {
					const result = await registerUser(
						cleanedPayload,
						accessToken
					);
					navigate(MANAGE_USERS);
					showSuccess("New user successfully added");
					return result;
				} catch (error) {
					showError("generalError");
					console.error("Error:", error);
				}
			} else if (userRol === "ADMIN") {
				try {
					const result = await registerUserAdmin(
						cleanedPayload,
						accessToken
					);
					navigate(MANAGE_USERS);
					showSuccess("New user successfully added");
					return result;
				} catch (error) {
					showError("generalError");
					console.error("Error:", error);
				}
			} else {
				showError("addUserRol");
				console.error("User rol invalid");
			}
		} catch (error) {
			showError("generalError");
			console.error("Error fetching organization types:", error);
		}
	};

	const handleCancel = (e) => {
		navigate(MANAGE_USERS);
	};

	return (
		<>
			<div className="add-user-form">
				<div className="add-user-form__heading">
					<div className="add-user-form__heading__back-button">
						<BackButtonComponent text={"Back to User list"} />
					</div>
					<div className="add-user-form__heading__title">
						<h1>Add new user</h1>
					</div>
				</div>
				<div className="add-user-form__form">
					<form>
						<div className="add-user-form__form__content">
							<div className="add-user-form__form__content__title">
								<h3>User information</h3>
							</div>
							<div className="add-user-form__form__content__row">
								<InputComponent
									type="text"
									name="firstname"
									value={formData.firstname}
									label="Name"
									placeholder="Insert name"
									onChange={handleChange}
								/>
								<InputComponent
									type="text"
									name="lastname"
									value={formData.lastname}
									label="Lastname"
									placeholder="Insert lastname"
									onChange={handleChange}
								/>
							</div>
							<div className="add-user-form__form__content__row">
								<InputComponent
									type="email"
									name="email"
									value={formData.email}
									label="Email"
									placeholder="Insert email"
									onChange={handleChange}
								/>
								<InputComponent
									type="tel"
									name="phone"
									value={formData.phone}
									label="Phone and country code (Optional)"
									placeholder="Insert phone number with the country code"
									onChange={handleChange}
								/>
							</div>

							<div className="add-user-form__form__content__title">
								<h3>User rol</h3>
							</div>
							<div className="add-user-form__form__content__checkbox">
								<div className="input-content">
									<div className="input-content__radios">
										<input
											type="radio"
											value="DEVELOPER"
											name="userType"
											id="DEVELOPER"
											checked={
												formData.userType ===
												"DEVELOPER"
											}
											onChange={handleSelectorChange}
										/>
										<label htmlFor="DEVELOPER">
											<div className="input-content__radios__radio">
												<div className="input-content__radios__radio__content">
													<span className="input-content__radios__radio__title developer">
														DEVELOPER
													</span>
													<span className="input-content__radios__radio__description">
														Role for whoever needs
														to manage the APIs
													</span>
												</div>
											</div>
										</label>

										<input
											type="radio"
											value="ADMIN"
											name="userType"
											id="ADMIN"
											checked={
												formData.userType === "ADMIN"
											}
											onChange={handleSelectorChange}
										/>
										<label htmlFor="ADMIN">
											<div className="input-content__radios__radio">
												<div className="input-content__radios__radio__content">
													<span className="input-content__radios__radio__title admin">
														ADMIN
													</span>
													<span className="input-content__radios__radio__description">
														With this role you can
														manage the company's
														users
													</span>
												</div>
											</div>
										</label>
									</div>
								</div>
							</div>

							<DividerComponent />

							<div>
								<div className="add-user-form__buttons">
									<ButtonComponent
										text={"Cancel"}
										onClick={handleCancel}
										color={"button-component--neutral"}
									/>
									<ButtonComponent
										text={"Add user"}
										onClick={handleSubmit}
									/>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default AddUserForm;
