import React, { useState, useEffect, useCallback } from "react";
import { useGlobalVar } from "../../../utils/GlobalContext";

import {
	error as showError,
	success as showSuccess,
} from "../../../utils/NotificationsContainer";

import {
	updateUserOrganization,
	updatePassword,
} from "../../../../services/ApiOrganization";

import { deleteUser } from "../../../../services/ApiAuth";

import InputComponent from "../../../atoms/input-component/InputComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import BackButtonComponent from "../../../atoms/back-button-component/BackButtonComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ModalComponent from "../../../molecules/modal-component/ModalComponent";
import AlertComponent from "../../../atoms/alert-component/alertComponent";

const EditAdministrator = ({ user }) => {
	const { accessToken } = useGlobalVar();

	//const { rowData } = location.state;
	const [isEditing, setIsEditing] = useState(false);

	const [updateAdminData, setUpdateAdminData] = useState({
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
		password: "",
	});
	const [showModal, setShowModal] = useState(false);

	/* useEffect(() => {
		if (rowData) {
			setUpdateAdminData({
				name: rowData.name || "",
				lastname: rowData.lastname || "",
				email: rowData.email || "",
				phone: rowData.phone || "",
				password: rowData.password || "*******************",
			});
		}
	}, [rowData]);
 */

	const resetData = useCallback(() => {
		if (user) {
			setUpdateAdminData({
				firstname: user.name || "",
				lastname: user.lastname || "",
				email: user.email || "",
				phone: user.phone || "",
				role: user.role || "",
				id: user.id || "",
			});
		}
	}, [user]);

	useEffect(() => {
		resetData();
	}, [resetData, user]);

	const handleChange = (name, value) => {
		if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				// Asegura que solo se acepten números y hasta 9 dígitos
				setUpdateAdminData({
					...updateAdminData,
					[name]: value,
				});
			}
		} else {
			setUpdateAdminData((prevUserData) => ({
				...prevUserData,
				[name]: value,
			}));
		}
	};

	const toggleEdit = () => {
		setIsEditing(true);
	};

	const handleCancel = () => {
		resetData();
		setIsEditing(false);
	};

	const handleModifyAdministrator = async (e) => {
		if (e) e.preventDefault();

		const cleanedPayload = JSON.parse(JSON.stringify(updateAdminData));

		try {
			await updateUserOrganization(cleanedPayload, accessToken);
			setIsEditing(false);

			showSuccess("User successfully modified");
		} catch (error) {
			showError("generalError");
			console.error("Error updating organization:", error);
		}
	};

	const handleUpdatePassword = async (e) => {
		if (e) e.preventDefault();

		const payload = {
			email: updateAdminData.email,
		};
		try {
			await updatePassword(payload, accessToken);

			showSuccess("Your request has been sent successfully");
		} catch (error) {
			showError("generalError");
			console.error("Error updating organization:", error);
		}
	};

	const handleDelete = async (e) => {
		if (e) e.preventDefault();

		const payload = {
			email: updateAdminData.email,
		};
		try {
			await deleteUser(payload, accessToken);
			setIsEditing(false);

			localStorage.setItem("reloadDeleted", "true");

			window.location.reload();
		} catch (error) {
			showError("generalError");
			console.error("Error updating organization:", error);
		}
	};

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	return (
		<div>
			<div className="request-subscription request-subscription__form add-admin">
				<div className="request-subscription__heading">
					<div className="request-subscription__heading__back-button">
						<BackButtonComponent
							text={"Back to Administrator list"}
							reload
						/>
					</div>
					<h3 className="mg mg--b-4">Administrator information</h3>
					<div className="input-content p p--b-2">
						<div className="request-detail__information">
							<div className="input-two">
								<InputComponent
									type="text"
									name="firstname"
									label="Firstname"
									value={updateAdminData.firstname}
									className={"input--large"}
									disabled={!isEditing}
									onChange={handleChange}
								/>
								<InputComponent
									type="text"
									name="lastname"
									label="Lastname"
									value={updateAdminData.lastname}
									className={"input--large"}
									disabled={!isEditing}
									onChange={handleChange}
								/>
							</div>
							<div className="input-two">
								<InputComponent
									type="email"
									name="email"
									label="Email"
									value={updateAdminData.email}
									disabled={true}
									onChange={handleChange}
								/>
								<InputComponent
									type="tel"
									name="phone"
									label="Phone and country code (Optional)"
									value={updateAdminData.phone}
									disabled={!isEditing}
									onChange={handleChange}
									placeholder="Phone number with the country code"
								/>
							</div>

							<div className="input-content p p--b-2">
								<label className="p p--b-1">Password</label>
								{!isEditing ? (
									<span className="data">
										***************
									</span>
								) : (
									<div className="user-settings__password">
										<ButtonComponent
											text={"Reset password by email"}
											color={"button-component--warning"}
											onClick={handleUpdatePassword}
										/>
										<span className="aclaration">
											We’ll send a link to reset it.
										</span>
									</div>
								)}
							</div>
						</div>
					</div>

					{!isEditing ? (
						<></>
					) : (
						<div>
							<DividerComponent />
							<div className=" edit-users__remove">
								<div className="edit-users__remove--text">
									<h4 className="edit-users__remove--text__title mg mg--b-2">
										Delete user
									</h4>
									<p className="edit-users__remove--text__subtitle">
										This user will not be able to access the
										administration of Developer Portal
									</p>
								</div>
								<div className="edit-users__remove--btn">
									<span className="admin-management__icon"></span>
									<ButtonComponent
										text={"Delete user"}
										onClick={openModal}
										bgColor={"button-component--remove"}
									/>
								</div>
							</div>
							<ModalComponent
								show={showModal}
								onClose={closeModal}
								size={"lg"}
							>
								<div className="modal-delete-user__header">
									<h2>Do you want to delete this user?</h2>
								</div>

								<div className="modal-delete-user__body">
									<AlertComponent
										text={`You will not allow this user to access the PagoNxt Payments management area`}
										icon={"icon-info"}
										bgColor={"bg--brand-warning-100"}
									/>
								</div>
								<div className="modal-delete-user__buttons">
									<div className="pair-buttons">
										<ButtonComponent
											text={"Cancel"}
											onClick={closeModal}
											color={"button-component--neutral"}
										/>
										<ButtonComponent
											text={"Remove access"}
											onClick={handleDelete}
											color={"button-component--danger"}
										/>
									</div>
								</div>
							</ModalComponent>
						</div>
					)}
					<DividerComponent />
					{!isEditing ? (
						<ButtonComponent
							text={"Edit"}
							onClick={toggleEdit}
							color={"button-component--neutral"}
						/>
					) : (
						<div className="pair-buttons mg mg--t-4">
							<ButtonComponent
								text={"Cancel"}
								onClick={handleCancel}
								color={"button-component--reject"}
							/>
							<ButtonComponent
								text={"Save changes"}
								onClick={handleModifyAdministrator}
								color={"button-component"}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default EditAdministrator;
