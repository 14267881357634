import React, { useState } from "react";
import { useGlobalVar } from "../../utils/GlobalContext";

import {
	error as showError,
	success as showSuccess,
} from "../../utils/NotificationsContainer";

import { DEVHUB } from "../../utils/Routes";

import ButtonComponent from "../../atoms/button-component/ButtonComponent";
import InputComponent from "../../atoms/input-component/InputComponent";

import { sendSupportForm } from "../../../services/ApiUsers";

// PLACEHOLDERS PARA INPUTS TEXT Y TEXTAREAS
const placeholders = {
	phone: "Insert your phone number with the country code",
	contactReason:
		"Please share your thoughts and suggestions to help us improve.",
};

const supportOptions = [
	{
		label: "Technical doubts",
		value: "Technical doubts",
	},
	{
		label: "Incidents",
		value: "Incidents",
	},
];

const FormSupport = ({ onClose }) => {
	const { accessToken, userEmail, nameOrganization, userData } = useGlobalVar();

	const [formData, setFormData] = useState({
		nameOrganization: nameOrganization,
		mail: userData.email,
		phone: "",
		queryType: "Technical doubts",
		contactReason: "",
	});

	const [files, setFiles] = useState([]);

	const typeSupportOptions = supportOptions.map((type) => ({
		label: type.label,
		value: type.value,
	}));

	const handleChange = (name, value) => {
		if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				// Asegura que solo se acepten números y hasta 9 dígitos
				setFormData({
					...formData,
					[name]: value,
				});
			}
		} else {
			setFormData((prevFormData) => {
				const keys = name.split(".");
				let newFormData = { ...prevFormData };
				let nested = newFormData;

				// Navegar por el objeto en caso de propiedades anidadas
				for (let i = 0; i < keys.length - 1; i++) {
					nested = nested[keys[i]];
				}

				nested[keys[keys.length - 1]] = value;

				return newFormData;
			});
		}
	};

	const handleFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);
		const validFiles = selectedFiles.filter(
			(file) => file.type === "image/jpeg" || file.type === "image/png"
		);

		setFiles((prevFiles) => [...prevFiles, ...validFiles]);
	};

	const handleRemoveFile = (index) => {
		setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
	};

	const isFormValid = () => {
		const { mail, phone, queryType, contactReason } = formData;

		// Asegurarse de que los campos obligatorios no estén vacíos
		return (
			mail && phone && queryType !== "type" && contactReason.trim() !== ""
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Validar el formulario
		if (!isFormValid()) {
			showError("allRequiredFields");
			return;
		}

		const data = new FormData();
		data.append("support", JSON.stringify(formData));

		files.forEach((file) => {
			data.append("attach_files", file);
		});

		try {
			const response = await sendSupportForm(formData, files, userData.email, accessToken);
			if (response) {
				showSuccess("Your support request has been sent successfully");
				onClose();
			}
		} 
		catch (error) {
			showError("generalError");
			console.error("Error al enviar el formulario:", error);
		}
	};

	return (
		<>
			<div className="form-support">
				<div className="form-support__header">
					<div className="form-support__header__title">
						<h3>Request to support team</h3>
					</div>
					<div className="form-support__header__description">
						<p>
							Before sending the request, check that there is no
							information that solves your problem in our
							documentation repository{" "}
							<span>
								<a
									rel="noreferrer"
									target="_blank"
									href={DEVHUB}
								>
									Developer portal
								</a>
								.
							</span>
						</p>
					</div>
				</div>
				<form onSubmit={handleSubmit}>
					<div className="form-support__form">
						<div className="form-support__form__section">
							<div className="form-support__form__title">
								Contact details
							</div>
							<div className="form-support__form__input-content form-support__form__input-content--row">
								<div className="input-content">
									<InputComponent
										type="text"
										name="mail"
										label="Email"
										value={formData.mail}
										placeholder={formData.mail}
										onChange={handleChange}
										className={"input--medium"}
										disabled
									/>
								</div>

								<div className="input-content">
									<InputComponent
										type="tel"
										name="phone"
										label="Phone"
										value={formData.phone}
										placeholder={placeholders.phone}
										onChange={handleChange}
										className={"input--medium"}
									/>
								</div>
							</div>
						</div>
						<div className="form-support__form__section">
							<div className="form-support__form__title">
								Request
							</div>
							<InputComponent
								type="select"
								name="queryType"
								id="reason"
								label="Query type"
								options={typeSupportOptions}
								value={formData.queryType}
								onChange={handleChange}
							/>

							<InputComponent
								type="textarea"
								name="contactReason"
								label="Explain the reason for the contact"
								value={formData.contactReason}
								placeholder={placeholders.contactReason}
								onChange={handleChange}
								className={"input--mediumlarge "}
							/>
						</div>

						{files.length > 0 ? (
							<div className="form-support__form__files">
								<ul>
									{files.map((file, index) => (
										<li key={index}>
											{file.name}{" "}
											<span
												onClick={() =>
													handleRemoveFile(index)
												}
												className="cross"
											></span>
										</li>
									))}
								</ul>
							</div>
						) : null}

						<div className="form-support__form__submit">
							<div className="form-support__form__submit__button">
								<ButtonComponent type="submit" text={"Send"} />
							</div>
							<div className="form-support__form__submit__files">
								<div className="input-content">
									<div className="input-content__files">
										<label htmlFor="file">
											Attach files{" "}
											<span className="format">
												{" "}
												(.jpg, .png)
											</span>
										</label>
										<input
											type="file"
											id="file"
											name="file"
											onChange={handleFileChange}
											accept="image/jpeg,image/png"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default FormSupport;
