import React, { useState } from "react";
import logo from "../assests/logo.svg";
import { Outlet } from "react-router";
import { error as showError } from "../utils/NotificationsContainer";
import "./FullScreenLayout.css";

import { useGlobalVar } from "../utils/GlobalContext";

import LinkComponent from '../atoms/link-component/LinkComponent';
import ModalComponent from "../molecules/modal-component/ModalComponent";
import FormBecomeCustomer from '../organisms/form-become-customer/FormBecomeCustomer';
import FormSupport from "../organisms/form-support/FormSupport";

import {
	HOME,
	DEVHUB,
  LEGAL_NOTICE,
  COOKIE_POLICY,
  CODE_CONDUCT,
  PRIVACY_NOTICE
} from "../utils/Routes";
import MenuLoggedComponent from "../molecules/menu-logged-component/MenuLoggedComponentComponent";

function FullScreenLayout() {
  const { userData, becomeCustomerSended } = useGlobalVar();
	const [showModal, setShowModal] = useState(false);
  const [showBecomeCustomerModal, setShowBecomeCustomerModal] = useState(false);

  const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

  const showPendingError = () => {
    showError("requestAlready");
  }

  return (
    <>
      {/* HEADER   */}
      <header className="header--logged">
        <div className="header--logged__container">
          <div className="header--logged__logo">
            <a href={HOME}>
              <img src={logo} alt="" />
            </a>
          </div>
          <div className="header--logged__buttons">
          
              <div className="header--logged__buttons__links">
                <div>
                  <LinkComponent text={'Developer portal'} icon={'icon-dev'} url={DEVHUB} externalURL={true}/>
                </div>
                <div onClick={openModal}>
                  <LinkComponent text={'Support'} icon={'icon-support'}/>
                </div>
                {
                  !userData?.client ?
                  <>
                      { becomeCustomerSended ? 

                        <div onClick={() => showPendingError()}>
                          <LinkComponent text={'Upgrade pending'} icon={'icon-pending'} bgColor={'bg-pending'}/>
                        </div> 

                        :
                        <div onClick={() => setShowBecomeCustomerModal(true)}>
                          <LinkComponent text={'Upgrade your plan'} icon={'icon-spark'} filled/>
                        </div> 
                      }
                  </>
                  : 
                  null
                }
                
              </div>
              <div className="header--logged__buttons__menu-logged">
                <MenuLoggedComponent name={'Name Lastname'} email={'name.lastname@domain.com'}/>
              </div>
          
          </div>
       </div>
      </header>

      <main>
        <div className="body-container">
          <div className="wrapper">
            <Outlet/>
          </div>
        </div>
      </main>

      <ModalComponent show={showModal} onClose={closeModal} size={'lg'}>
        <FormSupport onClose={closeModal}/>
      </ModalComponent>

      <ModalComponent show={showBecomeCustomerModal} onClose={() => setShowBecomeCustomerModal(false)} size={'xl'}>
        <FormBecomeCustomer onClose={() => setShowBecomeCustomerModal(false)}/>
      </ModalComponent>

      {/* FOOTER */}
      <footer>
        <div className="footer">
          <div className="footer__top">
            <div className="footer__top__copyright">
              <p></p>
            </div>
            <div className="footer__top__links">
              <LinkComponent text={'Privacy notice'} url={PRIVACY_NOTICE} />
              <LinkComponent text={'Terms and conditions'} url={LEGAL_NOTICE} />
              <LinkComponent text={'Cookie Policy'} url={COOKIE_POLICY} />
              <LinkComponent text={'Code of Conduct and Canal Abierto'} url={CODE_CONDUCT} />
            </div>
          </div>
          <div className="footer__bottom">
              <p className="footer__bottom__disclaimer">
                DISCLAIMER - PagoNxt Payments Services, S.L. is a Spanish company domiciled in Avda. de Cantabria s/n, Ciudad Grupo Santander, 28660, Boadilla del Monte (Madrid) and with tax identification number B-87959326. PagoNxt Payments Services, S.L. is registered in the Corporate Registry of Madrid, volume 36500, sheet 204, page M-655666.<br /> <br />

                © 2024 PagoNxt S.L. PagoNxt is a registered trademark. All rights reserved.
              </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default FullScreenLayout;
