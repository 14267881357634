import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalVar } from "../../../utils/GlobalContext";

import { error as showError, success as showSuccess } from "../../../utils/NotificationsContainer";

import { updateApi, deleteApi } from "../../../../services/ApiApis";

import InputComponent from "../../../atoms/input-component/InputComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import BackButtonComponent from "../../../atoms/back-button-component/BackButtonComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ModalComponent from "../../../molecules/modal-component/ModalComponent";
import AlertComponent from "../../../atoms/alert-component/alertComponent";

import { SETTINGS } from "../../../utils/Routes";

const EditAPI = ({ API }) => {
	const { accessToken, userEmail, userData, setLoggedOut } = useGlobalVar();

	const [isEditing, setIsEditing] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const navigate = useNavigate();

	const [updateApiData, setUpdateApiData] = useState({
		codApi: "",
		nameApis: "",
		description: "",
		enabled: true,
	});

	const logout = () => {
		setLoggedOut();
		navigate("/logout");
	}

	useEffect(() => {
		if (API) {
			setUpdateApiData({
				codApi: API.codApi || "",
				nameApis: API.nameApis || "",
				description: API.description || "",
				enabled: API.enabled || false,
			});
		}
	}, [API]);

	const editOrganization = () => {
		setIsEditing(true);
	};

	const handleChange = (name, value) => {
		setUpdateApiData((prevUserData) => ({
			...prevUserData,
			[name]: value,
		}));
	};

	// const toggleEdit = () => {
	// 	setIsEditing(true);
	// };

	const handleModifications = async () => {
		try {
			await updateApi(updateApiData, accessToken, userData.email);
			showSuccess('API edited successfully')
			setIsEditing(false);
		} catch (error) {
			if(error.response.status === 403){
				logout();
			}
			showError("generalError");
			console.error("Error updating organization:", error);
		}
	};

	const handleDesactivateAPI = () => {
		setUpdateApiData((prevData) => ({
			...prevData,
			enabled: false,
		}));
		closeModal();
	};

	const handleDeleteAPI = async () => {
		
		try {
			await deleteApi(updateApiData, accessToken, userData.email);

			localStorage.setItem('reloadAPIDeleted', 'true');

			window.location.reload();

		} catch (error) {
			showError("generalError");
			console.error("Error updating organization:", error);
		}
		navigate(SETTINGS);
	};

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	const handleToggleChange = () => {
		if (updateApiData.enabled) {
			// Si está activado, abrimos el modal
			openModal();
		} else {
			// Si está desactivado, lo activamos
			handleChange("enabled", true);
		}
	};

	return (
		<div className="edit-api">
			<div className="edit-api__heading">
				<div className="edit-api__heading__back-button">
					<BackButtonComponent text={'Back to APIs list'} reload/>
				</div>
				<h3 className="mg mg--b-4">API information</h3>
				<div className="input-content p p--b-2">
					<div className="edit-api__information">
						<InputComponent
							type="text"
							name="nameApis"
							label="Name"
							placeholder={"Insert API name"}
							value={updateApiData.nameApis}
							className={"input--large"}
							onChange={handleChange}
							disabled={!isEditing}
						/>
						<InputComponent
							type="text"
							name="description"
							label="Brief description"
							placeholder={"Insert a short description about API"}
							value={updateApiData.description}
							className={"input--large"}
							onChange={handleChange}
							disabled={!isEditing}
						/>
						<InputComponent
							type="toggle"
							name="enabled"
							label="Active API to use it"
							value={updateApiData.enabled}
							onChange={handleToggleChange}
							disabled={!isEditing}
						/>

						<ModalComponent
							show={showModal}
							onClose={closeModal}
							size={"medium"}
						>
							<h2>Do you want to deactivate this API?</h2>
							<DividerComponent />
							<AlertComponent
								text={`The API will not be accessible by users. This update doesn't affect actual subscribers but will be communicated to them.`}
								icon={""}
								bgColor={"bg--brand-warning-100"}
							/>
							<DividerComponent />
							<div className="pair-buttons">
								<ButtonComponent
									text={"Deactivate API"}
									onClick={handleDesactivateAPI}
									color={"button-component--neutral"}
								/>
								<ButtonComponent
									text={"Yes, delete API"}
									onClick={handleDeleteAPI}
									color={"button-component--danger"}
								/>
							</div>
						</ModalComponent>
					</div>
				</div>

				<DividerComponent />
				{!isEditing ? (
					<ButtonComponent
						text={"Edit"}
						onClick={editOrganization}
						color={"button-component--neutral"}
					/>
				) : (
					<div className="pair-buttons mg mg--t-4">
						<ButtonComponent
							text="Cancel"
							onClick={() => setIsEditing(false)}
							color="button-component--neutral"
						/>
						<ButtonComponent
							text={"Apply modifications"}
							onClick={handleModifications}
							color={"button-component"}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default EditAPI;
