import React, { useState, useEffect } from "react";

import { useGlobalVar } from "../../../utils/GlobalContext";

import { error as showError } from "../../../utils/NotificationsContainer";

import { registerMasterUser } from "../../../../services/ApiAuth";

import BackButtonComponent from "../../../atoms/back-button-component/BackButtonComponent";
import InputComponent from "../../../atoms/input-component/InputComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";

const CreateNewAdministrator = () => {
	const { accessToken, userData } = useGlobalVar();

	const [formData, setFormData] = useState({
		username: "",
		firstname: "",
		lastname: "",
		phone: "",
		active: true,
		email: "",
		password: "",
		organizationName: userData.organizationName,
		organizationCif: userData.organizationCif,
		organizationType: userData.organizationType,
	});

	useEffect(() => {
		setFormData((prevFormData) => ({
			...prevFormData,
			username: prevFormData.email,
		}));
	}, [formData.email]);

	const handleChange = (name, value) => {
		if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				// Asegura que solo se acepten números y hasta 9 dígitos
				setFormData({
					...formData,
					[name]: value,
				});
			}
		} else {
			setFormData((prevformData) => ({
				...prevformData,
				[name]: value,
			}));
		}
	};

	const handleCancel = () => {
		window.location.reload();
	};

	const handleAddAdministrator = async (e) => {
		if (e) e.preventDefault();

		try {
			const result = await registerMasterUser(formData, accessToken);

			localStorage.setItem("reloadMasterAdded", "true");

			window.location.reload();
			return result;
		} catch (error) {
			showError("generalError");
			console.error("Error:", error);
		}
	};
	return (
		<div>
			<div className="request-subscription request-subscription__form add-admin">
				<div className="request-subscription__heading">
					<div className="request-subscription__heading__back-button">
						<BackButtonComponent
							text={"Back to Administrator list"}
							reload
						/>
					</div>
					<h3 className="mg mg--b-4">Add new Administrator</h3>
					<div className="input-content p p--b-2">
						<div className="request-detail__information">
							<div className="input-two">
								<InputComponent
									type="text"
									name="firstname"
									value={formData.firstname}
									label="Name"
									placeholder="Insert name"
									onChange={handleChange}
								/>
								<InputComponent
									type="text"
									name="lastname"
									value={formData.lastname}
									label="Lastname"
									placeholder="Insert lastname"
									onChange={handleChange}
								/>
							</div>
							<div className="input-two">
								<InputComponent
									type="email"
									name="email"
									value={formData.email}
									label="Email"
									placeholder="Insert email"
									onChange={handleChange}
								/>
								<InputComponent
									type="tel"
									name="phone"
									value={formData.phone}
									label="Phone and country code (Optional)"
									placeholder="Insert phone"
									onChange={handleChange}
								/>
							</div>
						</div>
					</div>
					<DividerComponent />
					<div className="pair-buttons mg mg--t-4">
						<ButtonComponent
							text={"Cancel"}
							onClick={handleCancel}
							color={"button-component--reject"}
						/>
						<ButtonComponent
							text={"Add Administrator"}
							onClick={handleAddAdministrator}
							color={"button-component"}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateNewAdministrator;
