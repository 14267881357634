import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalVar } from "../../../utils/GlobalContext";

import {
	getSubscriptionDelete,
	deleteSubscription,
} from "../../../../services/ApiSubscriptions";

import InputComponent from "../../../atoms/input-component/InputComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import ModalComponent from "../../../molecules/modal-component/ModalComponent";
import RejectRequest from "../../../organisms/reject-request/RejectRequest";
import ApiSubscriptionStateComponentAdmin from "../../../atoms/api-subscription-state-component-admin/ApiSubscriptionStateComponentAdmin";

import { error as showError, success as showSuccess } from "../../../utils/NotificationsContainer";

import { REQUESTS_MANAGEMENT } from "../../../utils/Routes";

const DeleteRequestDetails = ({ currentData }) => {
	const { accessToken, userEmail, userData } = useGlobalVar();
	const navigate = useNavigate();

	const [subscriptionAPIResponse, setsubscriptionAPIResponse] =
		useState(null);

	const [showModal, setShowModal] = useState(false);
	const [rejectMessage, setRejectMessage] = useState("");

	const closeModal = () => setShowModal(false);
	const openModal = () => setShowModal(true);

	const handleAprobar = async (e) => {
		e.preventDefault();

		const data = subscriptionAPIResponse.subscriptionStateList;

		// Filtra los elementos que tienen claves numéricas, elimina 'name' y 'nameOrganization', y los convierte en un array
		const subscriptions = Object.keys(data)
			.filter((key) => !isNaN(key)) // Filtrar solo las claves numéricas
			.map((key) => {
				const { name, nameOrganization, ...rest } = data[key]; // Desestructurar y eliminar 'name' y 'nameOrganization'
				return rest; // Retornar el objeto sin 'name' y 'nameOrganization'
			});

		// Combina los datos que no son numéricos con el array de 'subscriptions'
		const updatedData = {
			name: currentData.nameApp,
			nameOrganization: currentData.organization,
			subscriptions: subscriptions, // Añadir el array de suscripciones sin 'name' y 'nameOrganization'
			type: "DELETE",
			done: true,
			rejected: false,
		};

	

		try {
			await deleteSubscription(
				updatedData,
				userData.email,
				accessToken
			);
			showSuccess('Updated request');
			navigate(REQUESTS_MANAGEMENT);
			// Aquí puedes manejar la respuesta exitosa, como actualizar el estado o mostrar un mensaje al usuario
		} catch (error) {
			showError("generalError");
			console.error("Error al eliminar la suscripción:", error);
			// Aquí puedes manejar el error, como mostrar un mensaje de error al usuario
		}
	};

	const handleReject = async (e) => {
		e.preventDefault();

		const data = subscriptionAPIResponse.subscriptionStateList;

		// Filtra los elementos que tienen claves numéricas, elimina 'name' y 'nameOrganization', y los convierte en un array
		const subscriptions = Object.keys(data)
			.filter((key) => !isNaN(key)) // Filtrar solo las claves numéricas
			.map((key) => {
				const { name, nameOrganization, ...rest } = data[key]; // Desestructurar y eliminar 'name' y 'nameOrganization'
				return rest; // Retornar el objeto sin 'name' y 'nameOrganization'
			});

		// Combina los datos que no son numéricos con el array de 'subscriptions'
		const updatedData = {
			name: currentData.nameApp,
			nameOrganization: currentData.organization,
			subscriptions: subscriptions, // Añadir el array de suscripciones sin 'name' y 'nameOrganization'
			type: "DELETE",
			rejected: true,
			done: false,
		};

		updatedData.mensaje = rejectMessage;

		try {
			await deleteSubscription(
				updatedData,
				userData.email,
				accessToken
			);
			showSuccess('Updated request');
			navigate(REQUESTS_MANAGEMENT);
			// Aquí puedes manejar la respuesta exitosa, como actualizar el estado o mostrar un mensaje al usuario
		} catch (error) {
			showError("generalError");
			console.error("Error al eliminar la suscripción:", error);
			// Aquí puedes manejar el error, como mostrar un mensaje de error al usuario
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const organization = currentData.organization;
			const nameApp = currentData.nameApp;
			const type = currentData.type;
			try {
				const response = await getSubscriptionDelete(
					organization,
					nameApp,
					type,
					userData.email,
					accessToken
				);
				setsubscriptionAPIResponse(response);
			} catch (error) {
				// setError(error);
			} finally {
				// setLoading(false);
			}
		};

		fetchData();
	}, [accessToken, currentData, userData.email]);

	return (
		<div className="subscribe-request-details">
			<div>
				{subscriptionAPIResponse
					? subscriptionAPIResponse.subscriptionStateList.map(
							(item, index) => (
								<div key={index}>
									<div>
										<h2 className="mg mg--b-4">
											Request for {item.environment}{" "}
											environment
										</h2>
										<div className="subscribe-request-details__apis__title">
											APIs subscription
										</div>
										<div className="request-detail__information">
											<div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
												<div className="input-content__checkboxes__checkbox__text">
													<div className="input-content__checkboxes__checkbox__text__title">
														<span>
														API ISO20022
														</span>
													</div>
													<div className="input-content__checkboxes__checkbox__text__description">
														<span>
														Brief description of the API utility
														</span>
													</div>
													<div className="input-content__checkboxes__checkbox__subscription">
														<ApiSubscriptionStateComponentAdmin state={item?.subscribeAPI?.apiIso}/>
													</div>
												</div>
											</div>  
											{/* <div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
												<div className="input-content__checkboxes__checkbox__text">
													<div className="input-content__checkboxes__checkbox__text__title">
														<span>
														API R-Transactions
														</span>
													</div>
													<div className="input-content__checkboxes__checkbox__text__description">
														<span>
														Brief description of the API utility
														</span>
													</div>
													<div className="input-content__checkboxes__checkbox__subscription">
														<ApiSubscriptionStateComponentAdmin state={item?.subscribeAPI?.apiRTran}/>
													</div>
												</div>
											</div>  */}
											{/* <div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
												<div className="input-content__checkboxes__checkbox__text">
													<div className="input-content__checkboxes__checkbox__text__title">
														<span>
														API Customer Portal
														</span>
													</div>
													<div className="input-content__checkboxes__checkbox__text__description">
														<span>
														Brief description of the API utility
														</span>
													</div>
													<div className="input-content__checkboxes__checkbox__subscription">
														<ApiSubscriptionStateComponentAdmin state={item?.subscribeAPI?.apiCustomer}/>
													</div>
												</div>
											</div>   */}
										</div>

										{item.environment === "PRE" ? 
											<> 
												{item?.publicKey ? (
													<InputComponent
														type="textarea"
														name="publicKey"
														label="Public Key"
														value={`-----BEGIN PUBLIC KEY-----\n${item?.publicKey}\n-----END PUBLIC KEY-----`}
														disabled={true}
														className={"input--extralarge"}
													/>
												) : null}
											</> : null
										}
										{item?.keyId ? (
											<InputComponent
												type="text"
												name="keyID"
												label="Key ID"
												value={item?.keyId}
												disabled={true}
												className={"input--medium"}
											/>
										) : null}

										{item?.insertIssuer ? (
											<InputComponent
												type="text"
												name="issuer"
												label="Issuer"
												value={item?.insertIssuer}
												disabled={true}
												className={"input--medium"}
											/>
										) : null}
									</div>

									<DividerComponent />

									{item.environment === "LIVE" ? (
										<>
											<div>
												<h3 className="mg mg--b-4">
													Notifier endpoint for LIVE
												</h3>
												{/* NOTIFICATIONS TYPE */}
												{item?.webhoot ? (
													<InputComponent
														type="text"
														name="notificationsType"
														label="Notifications type"
														value={"Webhook"}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.statusReport ? (
													<InputComponent
														type="text"
														name="notificationsType"
														label="Notifications type"
														value={
															"Status report hub messages"
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}

												{/* NOTIFIER SECURITY */}
												{item?.oauthSecurity ? (
													<InputComponent
														type="text"
														name="notifierSecurity"
														label="Notifier security"
														value={"OAuth 2.0"}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.basicSecurity ? (
													<InputComponent
														type="text"
														name="notifierSecurity"
														label="Notifier security"
														value={"HTTP Basic"}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{/* {item?.notSecurity ? (
													<InputComponent
														type="text"
														name="notifierSecurity"
														label="Notifier security"
														value={
															"Without security"
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null} */}

												{/* OAUTH SECURITY PARAMS */}
												{item?.oauthParam
													?.notificationUrl ? (
													<InputComponent
														type="text"
														name="funtionalEndpoint"
														label="Funtional endpoint"
														value={
															item?.oauthParam
																?.notificationUrl
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.oauthParam?.clientID ? (
													<InputComponent
														type="text"
														name="webhook"
														label="Webhook Client ID"
														value={
															item?.oauthParam
																?.clientID
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.oauthParam
													?.clientSecret ? (
													<InputComponent
														type="text"
														name="clientSecret"
														label="Client Secret"
														value={
															item?.oauthParam
																?.clientSecret
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.oauthParam?.tokenUrl ? (
													<InputComponent
														type="text"
														name="tokenURI"
														label="Token URI"
														value={
															item?.oauthParam
																?.tokenUrl
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}

												{/* BASIC SECURITY PARAMS */}
												{item?.basicSecurityParam
													?.notificationUrl ? (
													<InputComponent
														type="text"
														name="funtionalEndpoint"
														label="Funtional endpoint"
														value={
															item
																?.basicSecurityParam
																?.notificationUrl
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.basicSecurityParam
													?.user ? (
													<InputComponent
														type="text"
														name="user"
														label="User"
														value={
															item
																?.basicSecurityParam
																?.user
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.basicSecurityParam
													?.pass ? (
													<InputComponent
														type="text"
														name="password"
														label="Password"
														value={
															item
																?.basicSecurityParam
																?.pass
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}

												{/* WITHOUT SECURITY PARAMS */}
												{/* {item?.notSecurityParam
													?.tokenUrl ? (
													<InputComponent
														type="text"
														name="tokenURI"
														label="Token URI"
														value={
															item
																?.notSecurityParam
																?.tokenUrl
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null} */}
											</div>
											<DividerComponent />
										</>
									) : null}

									<div>
										<h3 className="mg mg--b-4">
											Confidential information
											from PagoNxt (
											{item.environment})
										</h3>
										<div className="subscribe-request-details__information-inputs">
											<div className="input-content">
												<div className="input-content__title">
													<span>
														Client ID
													</span>
												</div>
												<label>
													<input
														type="text"
														name="clientAppId"
														value={
															item.clientAppId
														}
														placeholder={""}
														autoComplete="off"
														disabled={true}
													/>
												</label>
											</div>
											<div className="input-content">
												<div className="input-content__title">
													<span>Secret</span>
												</div>
												<label>
													<input
														type="text"
														name="secret"
														value={
															item.secret
														}
														placeholder={""}
														autoComplete="off"
														disabled={true}
													/>
												</label>
											</div>
											{
												item.environment === 'SANDBOX' ?
												<>
													<div className="input-content">
														<div className="input-content__title">
															<span>URL</span>
														</div>
														<label>
															<input
																type="text"
																name="url"
																value={
																	item.url
																}
																placeholder={""}
																autoComplete="off"
																disabled={true}
															/>
														</label>
													</div>
													<div className="input-content">
														<div className="input-content__title">
															<span>Predefined Bearer token</span>
														</div>
														<label>
															<input
																type="text"
																name="url"
																value="7f697073-cddf-4b51-995f-aab611fe8ae9"
																placeholder={""}
																autoComplete="off"
																disabled={true}
															/>
														</label>
													</div>
												</>
												 : null
											}
										</div>
									</div>

									<DividerComponent />
										
								</div>
							)
					  )
					: null}

				<div className="pair-buttons">
					<ButtonComponent
						text={"Cancel"}
						onClick={openModal}
						color={"button-component--reject"}
					/>
					<ButtonComponent
						text={"Done"}
						onClick={handleAprobar}
						color={"button-component"}
					/>
				</div>
				<ModalComponent
					show={showModal}
					onClose={closeModal}
					size={"lg"}
				>
					<RejectRequest
						onMessageChange={setRejectMessage}
						rejectRequest={handleReject}
						onClose={closeModal}
					/>
				</ModalComponent>
			</div>
		</div>
	);
};

export default DeleteRequestDetails;
